/**********************/
/*DESKTOP FIRST DESIGN*/
/**********************/

@font-face {
    font-family: 'Notosans-Regular';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans.eot?#iefix') format('embedded-opentype'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans.woff2') format('woff2'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans.woff') format('woff'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans.ttf') format('truetype');
    /*font-weight: normal;
    font-style: normal;*/
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans-Bold';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Bold.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Bold.woff2') format('woff2'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Bold.woff') format('woff'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Bold.ttf') format('truetype');
    /*font-weight: bold;
    font-style: normal;*/
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans-Italic';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Italic.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Italic.woff2') format('woff2'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Italic.woff') format('woff'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-Italic.ttf') format('truetype');
    /*font-weight: normal;
    font-style: italic;*/
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans-BoldItalic';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-BoldItalic.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-BoldItalic.woff2') format('woff2'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-BoldItalic.woff') format('woff'),
         url('/ResourcePackages/NAVL/assets/dist/fonts/NotoSans-BoldItalic.ttf') format('truetype');
    /*font-weight: bold;
    font-style: italic;*/
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-Medium';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Medium.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Medium.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Medium.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Medium.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-Regular';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Regular.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Regular.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Regular.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Regular.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-Thin';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Thin.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Thin.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Thin.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Thin.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-SemiBold';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-SemiBold.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-SemiBold.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-SemiBold.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-ExtraBold';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-ExtraBold.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-ExtraBold.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-ExtraBold.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-Light';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Light.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Light.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Light.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Light.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend-Bold';
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Bold.eot');
    src: url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Bold.eot?#iefix') format('embedded-opentype'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Bold.woff2') format('woff2'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Bold.woff') format('woff'),
        url('/ResourcePackages/NAVL/assets/dist/fonts/Lexend-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, .form-blog-comments .title
{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
{
    display: block;
}

body
{
    line-height: 1;
    font-family: "Lexend-Regular",Arial,Helvetica,sans-serif !important;
    min-width: 0px !important;
    -ms-overflow-style: scrollbar;
}

ol, ul
{
    list-style: none;    
    margin: 1em auto 2em;
}

blockquote, q
{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after
{
    content: '';
    content: none;
}

table
{
    border-collapse: collapse;
    border-spacing: 0;
}

.blue
{
    color: #0068a4;
}

.red
{
    color: rgb(255, 25, 35);
}

/* jQuery UI - v1.11.4 - 2016-03-23
* http://jqueryui.com
* Includes: core.css, datepicker.css
* Copyright jQuery Foundation and other contributors; Licensed MIT */

.ui-helper-hidden
{
    display: none;
}

.ui-helper-hidden-accessible
{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-helper-reset
{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after
{
    content: "";
    display: table;
    border-collapse: collapse;
}

.ui-helper-clearfix:after
{
    clear: both;
}

.ui-helper-clearfix
{
    min-height: 0;
    /* support: IE7 */;
}

.ui-helper-zfix
{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(0);
    /* support: IE8 */;
}

.ui-front
{
    z-index: 100;
}

.ui-state-disabled
{
    cursor: default !important;
}

.ui-icon
{
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

.ui-widget-overlay
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ui-datepicker
{
    width: 17em;
    padding: 0.2em 0.2em 0;
    display: none;
}

.ui-datepicker .ui-datepicker-header
{
    position: relative;
    padding: 0.2em 0;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next
{
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover
{
    top: 1px;
}

.ui-datepicker .ui-datepicker-prev
{
    left: 2px;
}

.ui-datepicker .ui-datepicker-next
{
    right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover
{
    left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover
{
    right: 1px;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span
{
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title
{
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select
{
    font-size: 1em;
    margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year
{
    width: 45%;
}

.ui-datepicker table
{
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0 0 0.4em;
}

.ui-datepicker th
{
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    border: 0;
}

.ui-datepicker td
{
    border: 0;
    padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a
{
    display: block;
    padding: 0.2em;
    text-align: right;
    text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane
{
    background-image: none;
    margin: 0.7em 0 0 0;
    padding: 0 0.2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button
{
    float: right;
    margin: 0.5em 0.2em 0.4em;
    cursor: pointer;
    padding: 0.2em 0.6em 0.3em 0.6em;
    width: auto;
    overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current
{
    float: left;
}

.ui-datepicker.ui-datepicker-multi
{
    width: auto;
}

.ui-datepicker-multi .ui-datepicker-group
{
    float: left;
}

.ui-datepicker-multi .ui-datepicker-group table
{
    width: 95%;
    margin: 0 auto 0.4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group
{
    width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group
{
    width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group
{
    width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header
{
    border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane
{
    clear: left;
}

.ui-datepicker-row-break
{
    clear: both;
    width: 100%;
    font-size: 0;
}

.ui-datepicker-rtl
{
    direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev
{
    right: 2px;
    left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next
{
    left: 2px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover
{
    right: 1px;
    left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover
{
    left: 1px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane
{
    clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button
{
    float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group
{
    float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header
{
    border-right-width: 0;
    border-left-width: 1px;
}

html
{
    font-family: 'Lexend-Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1.388888888888889em;
}

body
{
    background: #fff;
    color: #474747;
    padding-top: 79px;
    font-size: 1em;
    line-height: 1.388888888888889em;
}

h1
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
    margin-bottom: 0.357142857142857em;
}

h2
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
    margin-bottom: 0.357142857142857em;
}

.head4
{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
}

.sfPageWrapper .main-navigation
{
    top: auto;
    position: static;
    -webkit-transform: none;
    transform: none;
}

.sfPageWrapper header.main-header .RadDockZone .rdTable
{
    width: auto;
}

div[placeholderid="headerActions"]
{
    position: absolute;
    right: 0;
}

div[placeholderid="headerActions"] > div
{
    float: left;
}

.sfPageWrapper header.main-header > div
{
    width: auto;
    max-height: 80px;
    display: inline-block;
}

div[placeholderid="headerActions"] > div .btn-quote
{
    height: 80px;
    display: block;
}

div[placeholderid="headerActions"] > div [href^="tel:"]
{
    display: block;
    box-sizing: content-box;
    margin: 0;
    height: 80px;
}

div[placeholderid="headerActions"] > div [href^="tel:"] span
{
    padding-right: 20px;
}

.orphans
{
    display: none;
}

.sfPageContainer .orphans
{
    display: block;
}


.hidden-above-400
{
    display: none;
}

@media only screen and (max-width: 400px)
{
    .head4
    {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 21px;
    }
}

h4
{
    font-size: 1.333333333333333rem;
    line-height: 1.25em;
    margin-bottom: 1em;
}


a
{
    color: inherit;
    text-decoration: none;
}

a:hover
{
    text-decoration: underline;
}

label, .form-blog-comments .title
{
    font-size: 1.166666666666667rem;
    line-height: 1.19047619047619em;
    padding-top: 0.714285714285714em;
    padding-bottom: 0.238095238095238em;
    display: block;
    text-align: left;
    font-family: 'Lexend-Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

button
{
    cursor: pointer;
    font-family: inherit;
    font-size: 1.166666666666667rem;
    box-sizing: border-box;
    background: #fff;
    border: none;
    color: #636363;
}

button:active, button:focus
{
    outline-color: #0068a4;
}

input, select, textarea
{
    font-family: inherit;
    font-size: 1.166666666666667rem;
    box-sizing: border-box;
    background: #fff;
    border: none;
    color: #636363;
    padding: 0 0.5em;
    line-height: 1.904761904761905em;
    height: 1.904761904761905em;
    font-family: 'Lexend-Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin: 0 0.75em 0 0;
}

hr
{
    background: #0068a4;
    height: 1px;
    border: none;
    margin: 1em 0;
    width: 100%;
}

table
{
    border-collapse: inherit;
    width: 100%;
    border: solid 1px #ff1923;
}

td, th
{
    border: 1px solid #0068a4;
    padding: 0.5rem;
}

ul
{
    padding-left: 1.5em;
    list-style: disc outside;
}

ol
{
    padding-left: 1.5em;
    list-style: decimal outside;
}

article p
{
    margin-bottom: 2em;
}

article h1, article h2, article h3
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
    margin-bottom: 0.357142857142857em;
}

article a
{
    color: #ff1923;
}

article > a
{
    display: block;
    margin-bottom: 2em;
}

article > img
{
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 2em;
}

.video-embed
{
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 2em;
}

.review-submission-thanks
{
    width: 100%;
    padding: 30px;
    background: #ebebeb;
    border: solid 1px #a7a9ac;
    box-sizing: border-box;
}

.review-submission-thanks .title
{
    font-size: 2.375em;
    margin: 0 0 15px;
}

[data-section="form-full"] .local-thanks
{
    display: none;
}

button.red, .btn-red, [data-section="form-full"] button, #form-default button, [data-section="form-full"] button[type="submit"], #form-default button[type="submit"], [data-section="form-preview"] button, .hero button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
}

button.red:hover, [data-section="form-full"] button:hover, #form-default button:hover, [data-section="form-full"] button[type="submit"]:hover, #form-default button[type="submit"]:hover, [data-section="form-preview"] button:hover, .hero button:hover
{
    border-color: #fff;
}

button.blue, [data-section="form-full"] .locations button, #form-default .locations button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
}

button.blue:hover, [data-section="form-full"] .locations button:hover, #form-default .locations button:hover
{
    border-color: #fff;
}

button.red-rounded
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
    border-radius: 24px;
}

button.red-rounded:hover
{
    border-color: #fff;
}

.cta-download
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
    font-size: 1.166666666666667rem;
    line-height: 1.904761904761905em;
    height: 1.904761904761905em;
    box-sizing: border-box;
    padding: 0 1.5em;
    display: inline-block;
}

.cta-download:hover
{
    border-color: #fff;
}

.cta-download:hover
{
    text-decoration: none;
}

ul.horizontal
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 3.333333333333333rem;
    padding: 0;
    list-style: none;
}

ul.horizontal li
{
    margin-right: 3.333333333333333rem;
}

ul.horizontal a
{
    font-size: 1.333333333333333rem;
    color: inherit;
}

ul.horizontal a + a
{
    display: block;
    margin-top: 1em;
}

ul.bullets
{
    padding: 0;
    margin: 0 0 2em;
    list-style: none;
}

ul.bullets ul
{
    list-style: none;
    padding-left: 12px;
    padding-top: 8px;
}

ul.bullets li
{
    position: relative;
    padding-left: 1em;
    margin-bottom: 0.5em;
}

ul.bullets li:before
{
    content: "•";
    color: #ff1923;
    position: absolute;
    left: 0;
}

.main-header
{
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: #fff;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    z-index: 999;
    border-bottom: #ff1923 solid 1px;
    box-sizing: border-box;
}

.sfPageContainer .main-header
{
    position: relative;
}

.utilityContainer
{
    display: none;
}

.sfPageContainer .utilityContainer
{
    display: block;
}

.img-left-bdr
{ /* hide these thumbnails in content where they weren't deleted */
    display: none;
}

.img-left
{ /* these ones still look like they belong there: /moving-help*/
    float: left;
    margin-right: 10px;
    padding: 3px;
    width: auto;
}

@media only screen and (max-width: 480px)
{
    .img-left
    {
        margin: 0 auto 12px;
        text-align: center;
        padding: 3px;
    }
}

.main-header .toggle
{
    height: 100%;
    width: 79px;
    background: #0068a4;
    border: none;
    font-size: 1.040555555555556rem;
    color: #fff;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    cursor: pointer;
    line-height: 84px;
}

.main-header .toggle:active, .main-header .toggle:focus
{
    outline: none;
}

.main-header #logo img
{
    height: 79px;
}

.main-header [href^="tel:"]
{
    color: #000;
    text-decoration: none;
    font-size: 2.4rem;
    line-height: 0.790201501382853em;
    text-align: right;
    margin: 30px 25px 0 auto;
    background-size: auto 38px;
    padding: 0 25px 0 0;
    position: relative;
    box-sizing: border-box;
    height: 100%;
}

.main-header [href^="tel:"] span
{
    color: #0068a4;
    font-size: 0.76451995258791em;
    display: block;
}

.main-header [href^="tel:"]:after
{
    content: '';
    position: absolute;
    top: -20px;
    right: 0%;
    width: 14px;
    height: 100%;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/icon-phone.svg") 50% 50% no-repeat;
}

.referral-phone
{
    visibility: hidden;
}

.referral-phone.loaded
{
    visibility: visible;
}

.main-header .btn-quote
{
    background: #ff1923;
    color: #fff;
    font-size: 1.268333333333333rem;
    line-height: 1.136224266316251em;
    text-align: center;
    box-sizing: border-box;
    padding-top: 0.75em;
    width: 79px;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.main-header .btn-go-back
{
    color: #fff;
    padding: 0 40px 0 75px;
    font-size: 1.590555555555556rem;
    line-height: 80px;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/icon-arrow-go-back.png") no-repeat 45px 50%, url("/ResourcePackages/NAVL/assets/dist/img/template/bg-button-go-back.jpg") no-repeat 0 50% #ff1923;
    background-size: auto, auto 100%;
    cursor: pointer;
}

.show-menu .main-header .toggle
{
    background: #ff1923;
}

.main-navigation
{
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 999;
    background: rgba(255,255,255,0.975);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out 0s;
    padding: 60px;
    /*width: calc(100% - 160px);*/
    width: 40%;
    box-sizing: border-box;
}

.main-navigation ul
{
    padding: 0;
    list-style: none;
}

.main-navigation nav > ul
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.main-navigation nav > ul > li
{
    width: 100%;
    min-width: 250px;
}

.main-navigation strong
{
    font-size: 1.333333333333333rem;
    color: #ff1923;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    padding-bottom: 0.291666666666667em;
}

.main-navigation a
{
    font-size: 0.944444444444444rem;
    color: #0068a4;
    display: block;
    text-decoration: none;
    padding: 0.764705882352941em 0;
}

.main-navigation a:hover
{
    text-decoration: underline;
}

.main-navigation a:before
{
    content: "▸";
    display: inline-block;
    color: #ff1923;
    padding-right: 0.5em;
}

.show-menu .main-navigation
{
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.main-content
{
    border-top: solid 9px #ff1923;
    border-bottom: solid 10px #ff1923;
}

.template-footer
{
    padding: 5.555555555555555em 0 0 0;
}

.template-footer ul
{
    padding: 0;
    list-style: none;
}

.template-footer > ul
{
    width: 90%;
    max-width: 1120px;
    margin: 0 auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 0.888888888888889rem;
    line-height: 1.5625em;
}

.template-footer > ul > li
{
    width: 20%;
}

.template-footer li li
{
    padding-bottom: 0.681818181818182em;
}

.template-footer li li strong
{
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block;
}

.template-footer + small
{
    font-size: 0.666666666666667rem;
    line-height: 1.666666666666667em;
    text-align: center;
    display: block;
    width: 90%;
    margin: 0 auto;
    padding: 6.666666666666667em 0 8.333333333333334em;
}

.template-footer .social
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.template-footer .social li.cta-call
{
    width: 100%;
    font-size: 1.611111111111111rem;
    line-height: 1em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.template-footer .social li.cta-call span
{
    display: block;
    font-size: 0.724137931034483em;
}

.template-footer .social li.cta-facebook, .template-footer .social li.cta-twitter, .template-footer .social li.cta-linkedin
{
    margin-right: 10px;
}

.template-footer .social li.cta-facebook a, .template-footer .social li.cta-twitter a, .template-footer .social li.cta-linkedin a
{
    width: 30px;
    height: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("/ResourcePackages/NAVL/assets/dist/img/template/sprite-social.png");
    background-repeat: no-repeat;
    display: block;
    border-radius: 50%;
}

.template-footer .social li.cta-facebook a
{
    background-position: 0 0;
}

.template-footer .social li.cta-twitter a
{
    background-position: 50% 0;
}

.template-footer .social li.cta-linkedin a
{
    background-position: 100% 0;
}

.template-forms
{
    background: #f7f7f7;
}

.template-forms h1
{
    padding: 60px 0 0 0;
    margin: 0;
    text-align: center;
}

.template-forms h1 img
{
    max-width: 90%;
}

#form-corporate, #form-international
{
    width: 90%;
    max-width: 650px;
    margin: 0 auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.page-corporate-form h1, .page-international-form h1, .page-corporate-form p, .page-international-form p
{
    font-size: 1.222222222222222rem;
    line-height: 1.272727272727273em;
    text-align: center;
    width: 100%;
}

.page-corporate-form h1, .page-international-form h1
{
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.page-corporate-form p, .page-international-form p
{
    margin-bottom: 30px;
}

.page-corporate-form fieldset, .page-international-form fieldset, .page-corporate-form fieldset input, .page-international-form fieldset input, .page-corporate-form fieldset select, .page-international-form fieldset select
{
    width: 100%;
}

.page-corporate-form fieldset, .page-international-form fieldset
{
    margin-bottom: 30px;
}

.page-corporate-form .col, .page-international-form .col
{
    width: 48.46153846153846%;
}

.page-corporate-form button, .page-international-form button
{
    margin: 0 auto 30px;
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
}

.page-corporate-form button:hover, .page-international-form button:hover
{
    border-color: #fff;
}

.page-international-form .legal, .sfContentBlock .legal
{
    margin-top: 17px;
    font-size: .8em;
    line-height: 1.4;
    text-align: justify;
}

.page-international-form button
{
    margin-bottom: 8px;
}

.form-corporate-inner, .form-international-inner
{
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.page-form-default #form-default
{
    padding: 80px 0;
}

.page-form-default .scholarship
{
    padding: 80px;
    position: relative;
    overflow: auto;
    margin-top: 50px;
    background-color: #ededed;
}

.page-form-default img
{
    max-width: 100%;
}

.page-form-default .locations
{
    margin: 0 auto;
}

.page-form-default h1
{
    padding: 0;
}

.page-form-default .move-size
{
    display: none;
    text-align: center;
}

.page-form-default #form-default .move-size button
{
    margin: 1em;
}

.page-form-default #form-default a.referral-phone.call-style
{
    text-transform: uppercase;
}

.page-form-default .show-error label[for="home-size"]:after
{
    content: "THIS SELECTION IS REQUIRED";
    background: #f00;
    color: #fff;
    padding: 0 1em;
}

.page-form-default .legal
{
    margin-top: 17px;
    font-size: .8em;
    line-height: 1.4;
    text-align: justify;
}

.page-form-default p
{
    text-align: center;
}

ul.city-list-navigation
{
    margin-top: 40px;
    list-style: none;
    padding-left: 30px;
}

ul.city-list-navigation li
{
    text-indent: -24px;
    padding-bottom: 12px;
}

ul.city-list-navigation li:before
{
    font-size: 20px;
    content: "•";
    color: #ff1923;
    padding-right: 8px;
}

ul.city-list-navigation li p
{
    text-indent: 0;
}

/* #region Unobtrusive Validation */

.field-validation-error
{
    color: #ff1923;
    margin-top: 4px;
    display: block;
}

/* #endregion Unobtrusive Validation */

[data-section="form-full"], #form-default, .default-form-thanks, .default-form-error
{
    background: #ededed;
}

.default-form-thanks-inner, .default-form-error-inner
{
    width: 90%;
    max-width: 646px;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    padding: 80px 0;
    text-align: center;
}

[data-section="form-full"] header, #form-default header
{
    background: url("/ResourcePackages/NAVL/assets/dist/img/temporary/img-home-form-header.jpg") no-repeat 100% 0 #f6f1e7;
    width: 100%;
    height: 432px;
}

[data-section="form-full"] header .heading, #form-default header .heading
{
    width: 90%;
    max-width: 1086px;
    font-size: 1.886666666666667rem;
    line-height: 1em;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

[data-section="form-full"] header .heading img, #form-default header .heading img
{
    display: block;
}

[data-section="form-full"] .form-wrapper, #form-default .form-wrapper
{
    width: 100%;
    overflow-x: hidden;
}

[data-section="form-full"] .form-wrapper .quote-carousel, #form-default .form-wrapper form
{
    width: 300%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

[data-section="form-full"] .form-wrapper [data-step], #form-default .form-wrapper form > *
{
    width: 33.333333333333336%;
    text-align: center;
}

[data-section="form-full"] .form-wrapper [data-step]
{
    margin: auto;
}

[data-section="form-full"] .form-wrapper .locations, #form-default .form-wrapper .locations
{
    margin: auto;
}

[data-section="form-full"] .locations, #form-default .locations
{
    width: 90%;
    max-width: 646px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 80px auto;
}

[data-section="form-full"] label, #form-default label
{
    width: 100%;
}

[data-section="form-full"] button, #form-default button
{
    margin: 0 auto;
}

[data-section="form-full"] input, #form-default input, [data-section="form-full"] select, #form-default select
{
    width: 100%;
    margin: 0;
}

#form-default fieldset
{
    width: 100%;
    margin: 0 0 1.666666666666667rem;
}

[data-section="form-full"] fieldset
{
    width: 100%;
}

[data-section="form-full"] [data-step] fieldset
{
    margin: 0 0 1.666666666666667rem;
}

[data-section="form-full"] [data-step] fieldset.move-size
{
    margin: 0;
}

#AppInfoWrapper
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
}


[data-section="form-full"] .col, #form-default .col, #ScholarWrapper .col
{
    width: 48.91640866873065%;
}

[data-section="form-full"] [data-form="4"], #form-default [data-form="4"]
{
    background: #0068a7;
}

[data-section="form-full"] .move-size, #form-default .move-size
{
    background: #0068a6;
    color: #fff;
    padding: 60px 0;
}

[data-section="form-full"] .move-size p, #form-default .move-size p
{
    text-align: center;
    font-size: 1.111111111111111rem;
    line-height: 1.3em;
}

[data-section="form-full"] .move-size p button, #form-default .move-size p button
{
    background: none;
    border: none;
    font: inherit;
    color: #ff3735;
    text-decoration: underline;
}

[data-section="form-full"] .move-size .headline, #form-default .move-size .headline
{
    font-size: 2.888888888888889rem;
    line-height: 1em;
    text-align: center;
    padding: 0.5em 0 0.75em;
}

[data-section="form-full"] .move-size label, #form-default .move-size label
{
    color: #fff;
}

[data-section="form-full"] .move-size label[for="size-selection"],
#form-default .move-size label[for="size-selection"]
{
    text-align: center;
    font-size: 2rem;
    line-height: 1.109722222222222em;
}

[data-section="form-full"] .move-size .quoteContinueMessage
{
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 24px;
    line-height: 1.109722222222222em;
}

[data-section="form-full"] .move-size .sizes, #form-default .move-size .sizes
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    max-width: 930px;
    margin: 0 auto;
}

[data-section="form-full"] .move-size .sizes label, #form-default .move-size .sizes label
{
    width: 33.333333333333336%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

[data-section="form-full"] .move-size .sizes [for="small"] strong, #form-default .move-size .sizes [for="small"] strong
{
    line-height: 130px;
}

[data-section="form-full"] .move-size .sizes [for="large"] strong, #form-default .move-size .sizes [for="large"] strong
{
    position: absolute;
    bottom: 45px;
}

[data-section="form-full"] .move-size .sizes .tip, #form-default .move-size .sizes .tip
{
    background: #fff;
    color: #0068a6;
    display: inline-block;
    font-size: 2.111111111111111rem;
    text-align: center;
    padding: 20px;
    border-radius: 25px;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    opacity: 0;
    transition: opacity 0.5s ease-out 0s;
    position: relative;
    margin: 40px 0;
    line-height: 1.2;
}

[data-section="form-full"] .move-size .sizes .tip:after, #form-default .move-size .sizes .tip:after
{
    content: "";
    position: absolute;
    top: 100%;
    left: 45%;
    height: 0;
    width: 0;
    border-right: 10px solid transparent;
    border-top: 20px solid #fff;
    border-left: 10px solid transparent;
}

[data-section="form-full"] .move-size .sizes input, #form-default .move-size .sizes input
{
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    opacity: 0;
}

[data-section="form-full"] .move-size .sizes strong, #form-default .move-size .sizes strong
{
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
    color: #0068a6;
    display: block;
    height: 120px;
    width: 100%;
    line-height: 140px;
    position: relative;
    z-index: 5;
}

[data-section="form-full"] .move-size .sizes input:checked + .tip, #form-default .move-size .sizes input:checked + .tip
{
    opacity: 1;
}

[data-section="form-full"] .move-size .sizes label:hover ~ svg, #form-default .move-size .sizes label:hover ~ svg, [data-section="form-full"] .move-size .sizes input:checked ~ svg, #form-default .move-size .sizes input:checked ~ svg
{
    opacity: 1;
}

[data-section="form-full"] .move-size .sizes svg, #form-default .move-size .sizes svg
{
    position: absolute;
    bottom: 110px;
    left: 50%;
    z-index: 5;
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    opacity: 0.5;
    z-index: 1;
}

/* #region Packing Calculator --------- */

#packing-calculator
{
    width: 545px;
    border-collapse: collapse;
    border: 1px solid #CCC;
    margin-bottom: 25px;
}

#packing-calculator th
{
    text-align: left;
    padding: 8px;
    color: #000;
    font-size: 1.2em;
}

#packing-calculator td
{
    padding-left: 16px;
    height: 20px;
}

#packing-calculator td, #packing-calculator th, #packing-rooms
{
    border: 0;
}

#packing-calculator label
{
    display: inline;
    font-weight: bold;
    padding: 6px 0px;
    font-size: 15px;
}

#packing-calculator input
{
    padding: 3px; /*border:1px solid #CCC */
}

#packing-calculator select
{
    padding: 3px;
    border: 1px solid #CCC;
    width: 150px;
}

#packing-calculator #packing-rooms th
{
    color: #F00;
    font-weight: bold;
    padding: 0px;
    font-size: 1em;
    height: 30px;
}

#packing-calculator #packing-rooms td
{
    padding-left: 12px;
    height: 20px;
}

#packing-calculator #packing-rooms label
{
    display: inline;
    margin: 0px 5px;
}

#packing-calculator #packing-rooms input
{
    display: inline;
    height: auto;
    border: 0;
}

#result, #result td, #result th, #result table
{
    border: 0;
    padding: 0;
}

#result b
{
    font-weight: bold;
}

#packing-calculator #packing-rooms select
{
    width: 45px;
}

#calculatorQuestions
{
    border: 1px solid #CCC;
    margin: 10px 0px 25px 0px;
    padding: 10px 10px 0px 10px;
    width: 342px;
    position: relative;
    top: 0px;
    left: 0px;
}

#calculatorQuestions legend, #calculatorResults legend, #calculatorRentBuyResults legend
{
    font-size: 1.2em;
    font-weight: bold;
    color: #21639F;
    position: relative;
    margin: 0px;
    padding: 0px;
    background: #FFF;
}

#calculatorQuestions input
{
    vertical-align: middle;
    border: #CCC 1px solid;
    padding: 2px 0px 4px 0px;
}

#calculatorQuestions label
{
    margin: 0px 0px 3px;
    padding: 3px 0px;
    cursor: hand;
}

#loanYears
{
    width: 110px;
    margin: 10px 0px 6px 83px;
}

#interestRate
{
    width: 110px;
    margin: 6px 4px 6px 71px;
}

#loanAmount
{
    width: 110px;
    margin: 6px 0px 6px 68px;
}

#annualTax
{
    width: 110px;
    margin: 6px 0px 6px 84px;
}

#annualInsurance
{
    width: 110px;
    margin: 6px 0px 6px 43px;
}

#desiredMortgage
{
    width: 110px;
    margin: 10px 0px 6px 42px;
}

#mortgageYears
{
    width: 110px;
    margin: 6px 0px 6px 39px;
}

#mortgageInterestRate
{
    width: 110px;
    margin: 6px 4px 6px 8px;
}

#annualPropertyTaxes
{
    width: 110px;
    margin: 6px 0px 6px 14px;
}

#monthlyDebt
{
    width: 110px;
    margin: 6px 0px 6px 30px;
}

.rentalPayment
{
    width: 90px;
    margin: 10px 0px 6px 25px;
}

.purchasePrice
{
    width: 90px;
    margin: 6px 0px 6px 4px;
}

.downPayment
{
    width: 90px;
    margin: 6px 0px 6px 10px;
}

.mortgageInterestRate
{
    width: 90px;
    margin: 6px 0px 6px 38px;
}

.keepHome
{
    width: 90px;
    margin: 6px 4px 6px 41px;
}

#sirva
{
    background: url(../img/template/logo-sirva.gif) no-repeat 215px 5px;
}

/* #endregion Packing Calculator --------- */

[data-section="form-full"] .move-size .sizes svg polygon, #form-default .move-size .sizes svg polygon
{
    fill: #fff;
}

[data-section="form-full"] .move-size .sizes label[for="small"] svg, #form-default .move-size .sizes label[for="small"] svg
{
    width: 31.93548387096774%;
}

[data-section="form-full"] .move-size .sizes label[for="medium"] svg, #form-default .move-size .sizes label[for="medium"] svg
{
    width: 45.806451612903224%;
}

[data-section="form-full"] .move-size .sizes label[for="large"] svg, #form-default .move-size .sizes label[for="large"] svg
{
    width: 66.12903225806451%;
}

[data-section="form-full"] .personal, #form-default .personal
{
    padding: 60px 0;
    text-align: center;
}

[data-section="form-full"] .personal > p, #form-default .personal > p
{
    text-align: center;
    font-size: 1.222222222222222rem;
    line-height: 1.136363636363636em;
    margin-bottom: 50px;
}

[data-section="form-full"] .personal .elements, #form-default .personal .elements
{
    width: 90%;
    max-width: 646px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
}

[data-section="form-full"] .personal p.legal
{
    width: 90%;
    max-width: 646px;
    text-align: justify;
    margin: 18px auto 0;
    font-size: .7em;
}

[data-section="form-full"] .field-validation-error
{
    text-align: left;
}

[data-section="form-full"] .personal button, #form-default .personal button
{
    margin: 0 auto;
}

[data-section="form-preview"]
{
    background: url("/ResourcePackages/NAVL/assets/dist/img/temporary/bg-home-hero-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    min-height: 600px;
    position: relative;
}

[data-section="form-preview"] .content
{
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
    /*padding-top: 0;*/
    padding-top: 7.5em;
    position: relative;
    z-index: 50;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*align-items: center;*/
    /*min-height: 600px;*/
}

.sfPageContainer [data-section="form-preview"] .content
{
    display: block;
}

[data-section="form-preview"] .content .home-form
{
    width: calc(45% - 20px);
}

.sfPageContainer [data-section="form-preview"] .content .home-form
{
    width: 100%;
}

[data-section="form-preview"] p, [data-section="form-preview"] h1, [data-section="form-preview"] h2
{
    color: #fff;
    width: 510px;
}

[data-section="form-preview"] .headline-1
{
    font-size: 1.38rem;
    line-height: 0.885668276972625em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    padding-bottom: 0.9em;
    max-width: 100%;
    color: #fff;
}

[data-section="form-preview"] .headline-2
{
    font-size: 2.185rem;
    line-height: 0.966183574879227em;
    padding-bottom: 0.394736842105263em;
    max-width: 100%;
    color: #fff;
}

[data-section="form-preview"] .headline-3
{
    font-size: 2.185rem;
    line-height: 0.966183574879227em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: .4em;
    max-width: 100%;
    color: #fff;
}

[data-section="form-preview"] label
{
    color: #fff;
}

[data-section="form-preview"] form
{
    width: 340px;
}

[data-section="form-preview"] button
{
    margin-top: 2.103337905807042em;
}

.start-animations [data-section="form-preview"]:after
{
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

[data-section="form-preview"] .content .home-form input[type="text"]
{
    min-width: 80%;
}

[data-section="scroll arrow"]
{
    width: 100%;
    display: block;
    text-align: center;
    color: #ff1923;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.072777777777778rem;
    margin: 45px 0;
}

[data-section="scroll arrow"] img
{
    display: block;
    width: 48px;
    height: auto;
    margin: 0 auto 3px;
}

[data-section="types"]
{
    width: 90%;
    max-width: 1190px;
    margin: 80px auto;
}

[data-section="types"] ul
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    list-style: none;
}

[data-section="types"] li
{
    width: 24.6218487394958%;
    box-sizing: border-box;
    text-align: center;
    padding: 0 1em;
    font-size: 0.888888888888889rem;
    line-height: 1.5625em;
}

[data-section="types"] img
{
    width: 35.83617747440273%;
    max-width: 293px;
    display: block;
    margin: 0 auto 10px;
}

[data-section="types"] strong, [data-section="types"] .service-strong
{
    font-size: 1.222222222222222rem;
    line-height: 1.136363636363636em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block;
    text-transform: uppercase;
}

[data-section="types"] a
{
    color: #ff1923;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}

[data-section^="resources"]
{
    background: #0068a6;
    padding: 50px 0;
}

[data-section^="resources"] p, [data-section^="resources"] ul
{
    width: 90%;
    margin: 0 auto;
    max-width: 1076px;
}

[data-section^="resources"] p
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
    color: #fff;
    text-align: center;
    margin-bottom: 1.19047619047619em;
}

[data-section^="resources"] ul
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0;
    list-style: none;
}

[data-section^="resources"] li
{
    width: 28.899082568807337%;
    margin: 1.25em auto;
}

[data-section^="resources"] ul a
{
    width: 100%;
    background: #fff;
    border: #3386b8 solid 6px;
    box-sizing: border-box;
    font-size: 0.833333333333333rem;
    line-height: 1.466666666666667em;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3.933333333333333em;
    color: #ff1923;
}

[data-section^="resources"] ul a strong
{
    font-size: 1.416666666666667rem;
    line-height: 0.862745098039216em;
    color: #0068a4;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block;
    text-transform: uppercase;
}

[data-section^="resources"] ul a span
{
    margin: 10px 20px 10px 0;
    width: 57.6271186440678%;
}

[data-section^="resources"] ul a:hover
{
    border-color: #ff1923;
    text-decoration: none;
}

[data-section^="resources"] picture
{
    width: 42.3728813559322%;
    text-align: center;
    margin: 10px 0;
}

[data-section^="resources"] picture img
{
    width: 64%;
    height: auto;
}

[data-section^="resources"] .Link > a
{
    font-size: 1rem;
    color: #fff;
    text-align: center;
    display: block;
    margin-top: 1em;
}

.reviewsLayout
{
    padding: 50px 0;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.reviewsLayout-heading .title
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
    text-align: center;
    margin-bottom: 1.19047619047619em;
}

.reviewsLayout-row
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 9.583333333333334%;
    position: relative;
    padding: 0;
    list-style: none;
}

.reviewsLayout-row:before
{
    content: "";
    position: absolute;
    top: 0%;
    right: calc(100% + 10px);
    width: 72px;
    height: 68px;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/icon-review-quote.jpg") no-repeat 0 0;
}

.reviewsLayout-row:after
{
    content: "";
    position: absolute;
    top: 0%;
    left: calc(100% + 10px);
    width: 72px;
    height: 68px;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/icon-review-quote.jpg") no-repeat 0 0;
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.reviewsLayout-column
{
    width: 47.42268041237113%;
}

.reviewCallout
{
    font-size: 0.888888888888889rem;
    line-height: 1.5625em;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.reviewCallout-text
{
    width: 57.608695652173914%;
}

.reviewCallout-readmore
{
    transition: height 500ms;
    overflow: hidden;
    max-height: 75px;
}

.reviewCallout-img
{
    width: 36.95652173913043%;
}

.reviewCallout-img img
{
    border-radius: 50%;
    width: 100%;
}

.reviewCallout-text strong
{
    font-size: 1.222222222222222rem;
    line-height: 1.136363636363636em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.227272727272727em;
}

.reviewCallout-text a
{
    display: block;
    color: #ff1923;
}

.reviewCallout-text:after
{
    content: "";
    display: block;
    height: 25px;
    width: 165px;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/icon-stars.png") no-repeat 0 0;
    margin-top: 10px;
}

.reviewCallout[data-stars="0"] .reviewCallout-text:after
{
    width: 0px;
}

.reviewCallout[data-stars="1"] .reviewCallout-text:after
{
    width: 33px;
}

.reviewCallout[data-stars="2"] .reviewCallout-text:after
{
    width: 66px;
}

.reviewCallout[data-stars="3"] .reviewCallout-text:after
{
    width: 99px;
}

.reviewCallout[data-stars="4"] .reviewCallout-text:after
{
    width: 132px;
}

.reviewCallout[data-stars="5"] .reviewCallout-text:after
{
    width: 165px;
}

input.hasDatepicker
{
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/icon-datepicker.png") no-repeat 99% 50% #fff;
}

.ui-datepicker
{
    background: #fff;
    border: solid 1px #0068a4;
}

.ui-datepicker .ui-datepicker-prev:hover, .ui-datepicker .ui-datepicker-next:hover
{
    text-decoration: none;
    color: #ff1923;
}

.ui-datepicker .ui-datepicker-prev-hover
{
    left: 2px;
    top: 2px;
}

.ui-datepicker .ui-datepicker-next-hover
{
    right: 2px;
    top: 2px;
}

.ui-datepicker .ui-datepicker-prev:before, .ui-datepicker .ui-datepicker-next:after
{
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 20%;
}

.ui-datepicker .ui-datepicker-prev:before
{
    content: "❰";
}

.ui-datepicker .ui-datepicker-next:after
{
    content: "❱";
}

.ui-datepicker table
{
    border: none;
}

.ui-datepicker th, .ui-datepicker td
{
    border: solid 1px #0068a4;
}

.ui-datepicker .ui-datepicker-unselectable
{
    opacity: 0.5;
    background: #d0d0d0;
}

.ui-datepicker .ui-state-hover
{
    color: #ff1923;
}

.ui-datepicker .ui-datepicker-today
{
    background: #0068a4;
}

.ui-datepicker .ui-datepicker-today a
{
    color: #fff;
}

.stats-replace
{
    visibility: hidden;
}

.sfPageContainer .stats-replace
{
    visibility: visible;
}

.stats-visible
{
    visibility: visible;
}

.quick-form
{
    background: #0068a4;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #fff;
    padding: 20px;
    border-top: solid 1px #fff;
}



.quick-form .fields
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.quick-form .logo
{
    width: 100%;
    text-align: center;
    margin: 0 0 20px 0;
}

.quick-form .logo img
{
    width: 90%;
    max-width: 525px;
    height: auto;
    display: inline-block;
}

.quick-form .title
{
    font-size: 1.777777777777778rem;
    margin-right: 0.4921875em;
}

.quick-form .footer
{
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
    margin-top: 0.5em;
}

.quick-form .footer a
{
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.quick-form label
{
    padding-top: 0;
}

.quick-form button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
}

.quick-form button:hover
{
    border-color: #fff;
}

.column-2ndary .quick-form
{
    border-top: solid 10px #ff1923;
    position: relative;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    margin-bottom: 2em;
}

.column-2ndary .quick-form:before
{
    content: "";
    height: 1px;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
}

.column-2ndary .quick-form .title
{
    margin: 0 0 1em;
    font-size: 1.333333333333333rem;
    text-align: center;
    width: 100%;
}

.column-2ndary .quick-form fieldset
{
    width: 100%;
    margin: 0 0 1em;
}

.column-2ndary .quick-form input
{
    width: 100%;
    margin: 0;
}

.column-2ndary .quick-form button
{
    margin: 0 auto;
    display: block;
}

.column-2ndary .quick-form .footer
{
    font-size: 1.333333333333333rem;
}

.column
{
    width: 90%;
    max-width: 1220px;
    margin: 50px auto;
}

.columns
{
    width: 90%;
    max-width: 1220px;
    margin: 50px auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.columns .column-2ndary
{
    width: 27.459016393442624%;
}

.columns .column-primary
{
    width: 68.11475409836065%;
}

.columns .column-primary img
{
    max-width: 100%;
}

.sidebar-menu-title
{
    font-size: 1.777777777777778rem;
    line-height: 0.9375em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    padding: 0 0 0.625em 0;
    margin: 0;
    text-transform: uppercase;
}

.sidebar-menu ul
{
    font-size: 21px;
    line-height: normal;
    list-style: none;
    padding: 0 0.952380952380952em 0.952380952380952em 0.952380952380952em;
    margin: 0;
    background: #7f7f7f;
    border-bottom: solid 3px #fff;
}

.sidebar-menu button
{
    width: 100%;
    margin: 0;
    padding: 0 20px;
    text-align: left;
    position: relative;
    font-size: 1.166666666666667rem;
    line-height: 3.095238095238095em;
    height: 3.095238095238095em;
    background: #fff;
    border-top: solid 2px #ff1923;
    border-right: none;
    border-bottom: solid 2px #fff;
    border-left: none;
    color: #ff1923;
}

.sidebar-menu button:after
{
    float: right;
    font-size: 2.333333333333333rem;
    line-height: 1.261904761904762em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.sidebar-menu button:hover, .sidebar-menu button:active, .sidebar-menu button:focus
{
    outline: none;
    border-bottom-color: #ff1923;
}

.sidebar-menu a, .sidebar-menu a:link, .sidebar-menu a:visited, .sidebar-menu a:hover, .sidebar-menu a:active
{
    color: #fff;
    padding: 0.5em 0;
    display: inline-block;
}

.sidebar-menu .active
{
    color: #ffa500;
}

.sidebar-menu [data-state="default"] li:nth-child(n+5)
{
    display: none;
}

.sidebar-menu [data-state="default"] + button:before
{
    content: attr(data-more-text);
}

.sidebar-menu [data-state="default"] + button:after
{
    content: '+';
}

.sidebar-menu [data-state="open"] + button:before
{
    content: attr(data-less-text);
}

.sidebar-menu [data-state="open"] + button:after
{
    content: '-';
}

.sidebar-menu [data-state="closed"] + button:before
{
    content: attr(data-more-text);
}

.sidebar-menu [data-state="closed"] + button:after
{
    content: '+';
}

.hero
{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    text-align: center;
    color: #fff;
}

.quick-form + .hero
{
    border-top: none;
}

.hero h1
{
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 0.75em;
    text-transform: uppercase;
}

.hero h2
{
    font-size: 1.777777777777778rem;
    line-height: 1.25em;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 0.75em;
}

.hero p
{
    font-size: 1.777777777777778rem;
    line-height: 1.0625em;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 0.75em;
}

.hero .fields
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 90%;
    margin: 0 auto;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hero input
{
    width: 100%;
    margin: 0;
}

.hero button
{
    margin: 1em auto 0;
}

.page-international .hero .fields, .hero .intl-fields
{
    max-width: 516px;
}

.page-international .hero fieldset, .hero .fields-default fieldset, .hero .intl-fields fieldset
{
    width: calc(50% - 9px);
}

.hero .fields-default
{
    max-width: 516px;
}

.page-corporate .hero .fields
{
    max-width: 828px;
}

.page-corporate .hero fieldset
{
    width: calc(33.333334% - 9px);
}

.page-corporate .hero
{
    padding: 100px 0;
    background-image: url("/ResourcePackages/NAVL/assets/dist/img/temporary/bg-corporate-hero.jpg");
}

.page-moving-checklist
{
    position: relative;
}

.page-moving-checklist .hero
{
    padding: 150px 0;
    background-image: url("/ResourcePackages/NAVL/assets/dist/img/temporary/bg-checklist-hero.jpg");
}

.page-international .hero
{
    padding: 100px 0;
    background-image: url("/ResourcePackages/NAVL/assets/dist/img/temporary/bg-international-hero.jpg");
}

.hero-widgetControlled
{
    padding: 175px 0;
    background-image: url("/ResourcePackages/NAVL/assets/dist/img/temporary/bg-house-hold.jpg"); /* just a default */
    min-height: 225px;
}

.agents-list
{
    margin: 2em 0;
    padding: 0;
    list-style: none;
}

.agents-list li
{
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: solid 1px #000;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.agents-list li > *
{
    width: 48%;
}

.agents-list strong
{
    font-size: 1.555555555555556rem;
}

.agents-list strong, .agents-list span
{
    margin-bottom: 0.25em;
}

.agents-list span
{
    text-align: right;
}

.agents-list a
{
    display: block;
    color: #ff1923;
    min-width: 50%;
}

.agents-list .rating-count
{
    display: none;
}

.agents-list .rating-text
{
    height: 23px;
    margin-top: 0.5em;
    display: inline-block;
    vertical-align: middle;
}

.agents-list .reviews-anchor
{
    display: inline;
    text-align: right;
    margin-top: 11px;
}

#cityPage-map
{
    height: 420px;
    margin-bottom: 2em;
}

.mobile-only
{
    display: none;
}

.language-selector
{
    display: inline-block;
    position: absolute;
    right: 100px;
    top: 5px;
    z-index: 99999999;
}

.form-language-selector
{
    right: 284px;
}

.language-selector ul
{
    list-style-type: none;
    margin: 0;
}

.language-selector li
{
    display: inline-block;
}

.language-selector a
{
    color: #000;
    font-weight: 700;
    position: relative;
    margin: 0 7px;
}

.language-selector a:hover
{
    text-decoration: underline;
}

.language-selector a:after
{
    content: " | ";
    position: absolute;
    right: -12px;
}

.language-selector li:last-child a:after
{
    content: "";
}

@media only screen and (max-width: 1100px)
{
    .quick-form.mobile-only
    {
        /* full width if needed: width: 111.11111111111%;    margin-left: -5.5555555555%;    box-sizing: border-box; */
        background: #0068a4;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        color: #fff;
        padding: 20px;
        border-top: solid 1px #fff;
        margin-bottom: 40px;
    }

    .quick-form.mobile-only .logo
    {
        max-width: 525px;
        margin-left: auto;
        margin-right: auto;
    }

    #cityPage-map
    {
        height: 300px;
    }

    .quick-form.mobile-only fieldset
    {
        width: calc(50% - 9px);
    }

    .quick-form.mobile-only input
    {
        width: 100%;
        margin: 0;
    }

    .quick-form.mobile-only button
    {
        margin: 1em auto 0;
    }

    .quick-form.mobile-only a
    {
        color: inherit;
    }

    .quick-form.mobile-only .footer
    {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 768px)
{

    .main-header #logo img
    {
        height: 49px;
    }

    .page-agent-profile .contact-button-wrap
    {
        order: 1;
    }

    .btn-red.mobile-only
    {
        display: inline-block;
        margin-bottom: 8px;
    }

    .main-content .quick-form.mobile-only
    {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    #cityPage-map
    {
        height: 200px;
    }

    .agents-list .rating-text
    {
        display: none;
    }

    .language-selector
    {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .contact-button-wrap {
        display: none;
    }

    .agent-details {
        flex-flow: column;
        flex-direction: column-reverse;
    }

        .agent-details .quick-form {
            display: flex !important;
            width: 90% !important;
            margin: 1rem 0rem;
        }

            .agent-details .quick-form fieldset {
                width: 100%;
            }

    .column {
        margin: 1rem auto;
    }
}

@media (max-width: 600px)
{
    .quick-form.mobile-only fieldset
    {
        width: 100%;
        margin: 0 0 1em;
    }
}

.breadcrumbs
{
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    padding: 0;
    list-style: none;
    width: 90%;
    max-width: 1220px;
    margin: 50px auto;
}

.breadcrumbs li
{
    display: inline;
}

.breadcrumbs li:last-child:after
{
    display: none;
}

.breadcrumbs li:after
{
    content: '>';
    color: #ff1923;
    padding: 0 5px;
}

.breadcrumbs a
{
    color: #ff1923;
}

.featured-articles
{
    width: 90%;
    max-width: 1220px;
    margin: 50px auto;
    overflow: hidden;
}

.featured-articles .image-background
{
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 320px;
}

.featured-articles-inner
{
    margin-top: 1.5rem;
}

.featured-articles h2
{
    font-weight: 400;
    margin-bottom: 25px;
}


.featured-articles a
{
    color: #ff1923;
}

.featured-articles > p
{
    width: 60%;
}

.featured-articles ul
{
    list-style: none;
    padding-left: 0;
}


.featured-articles .title
{
    display: block;
    font-size: 42px;
    line-height: 1em;
    padding: 0.5em 0;
}

.featured-articles a:last-of-type
{
    display: block;
    padding-top: 1em;
}

.featured-articles img
{
    width: 100%;
}

.headline-with-search
{
    width: 90%;
    max-width: 1220px;
    margin: 50px auto;
    position: relative;
}

.sfPageEditor.headline-with-search .headline
{
    max-width: calc(100% - 250px);
}

.headline-with-search .search-form
{
    position: absolute;
    top: 0;
    right: 0;
    min-width: 250px;
}

.search-form label
{
    width: 100%;
}

.search-form fieldset
{
    position: relative;
}

.search-form input
{
    border: solid 1px #acacac;
    padding-right: 40px;
    width: 100%;
}

.ui-autocomplete, .ui-autocomplete .ui-menu-item
{
    background-color: #ffffff;
    list-style: none;
}

.ui-autocomplete
{
    background-color: #ffffff;
    list-style: none;
    border: 1px solid #acacac;
    padding: 0;
}

.ui-autocomplete .ui-menu-item
{
    padding: 5px 10px;
}

.ui-autocomplete .ui-menu-item:hover
{
    background-color: #ff1923;
    color: #fff;
}

.search-form button
{
    position: absolute;
    right: 1px;
    top: 1px;
    height: 38px;
    width: 38px;
    padding: 0;
}

.search-form img
{
    display: block;
    height: 100%;
}

.share-icons-list
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    padding: 0;
    list-style: none;
}

.share-icons-list li
{
    margin: 0 5px;
}

.share-icons-list li:last-child
{
    margin: 0 0 0 5px;
}

.share-icons-list a
{
    display: block;
    position: relative;
}

.share-icons-list a img
{
    display: block;
}

.share-icons-list a:before, .share-icons-list a:after
{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    background: #ff1923;
    transition: all 0.5s ease-in-out 0s;
}

.share-icons-list a:before
{
    bottom: 100%;
}

.share-icons-list a:after
{
    top: 100%;
}

.share-icons-list a:hover:before, .share-icons-list a:hover:after
{
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.agent-map
{
    position: relative;
}

.agent-map .bg
{
    width: 100%;
    display: block;
}

.agent-map #map
{
    height: 822px;
}



@media only screen and (max-width: 700px)
{
    .agent-map #map
    {
        height: auto;
    }

    .page-form-default img
    {
        max-width: 90%;
    }
}

.agent-controls
{
    width: 90%;
    max-width: 1220px;
    margin: 50px auto;
    border-top: solid 1px #ccc;
    padding: 40px 35px;
    box-sizing: border-box;
}

.agent-controls h2
{
    margin: 0;
}

.agent-controls .header, .agent-controls .footer
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.agent-controls .header
{
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.agent-controls .footer
{
    padding: 0 75px;
    margin-top: 1em;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.agent-controls .footer > button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
}

.agent-controls .footer > button:hover
{
    border-color: #fff;
}

.agent-controls .footer fieldset
{
    width: 515px;
}

.no-geolocation [data-geo-location], .no-geolocation [data-geo-location]:hover
{
    cursor: default !important;
    background: #636363 !important;
    color: #808080 !important;
    opacity: 0.25 !important;
}

.page-find-an-agent-results .agent-controls
{
    width: calc(100% - 40px);
    margin: 0;
    padding: 0;
    position: absolute;
    top: 10px;
    border: none;
}

.page-find-an-agent .agent-controls img
{
    margin-bottom: 0.5em;
}

.page-find-an-agent-results .agent-controls > button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
    margin: 1em 0;
    width: 100%;
}

.page-find-an-agent-results .agent-controls > button:hover
{
    border-color: #fff;
}

.agent-map .agent-results
{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 475px;
    background: rgba(255,255,255,0.85);
    z-index: 1;
    padding: 150px 20px 0;
    box-sizing: border-box;
}

.agent-map .agent-results ul
{
    list-style: none;
    padding: 25px 55px;
    margin: 0;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.agent-map .agent-results ul li
{
    font-size: 1.222222222222222rem;
    line-height: 1.181818181818182em;
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: solid 1px #0068a4;
}

.agent-map .agent-results ul li:last-child
{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.agent-map .agent-results ul strong
{
    display: block;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.agent-map .agent-results ul a
{
    display: block;
    color: inherit;
}

.agent-map .agent-results ul .btn-learn
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
    font-size: 1.166666666666667rem;
    line-height: 1.904761904761905em;
    height: 1.904761904761905em;
    box-sizing: border-box;
    padding: 0 1.5em;
    display: inline-block;
    margin: 1em 0 0 0;
}

.agent-map .agent-results ul .btn-learn:hover
{
    border-color: #fff;
}

.agent-map .agent-results ul .btn-learn:hover
{
    text-decoration: none;
}

.agent-map .markers
{
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0;
    list-style: none;
}

.agent-map .markers li
{
    position: absolute;
}

.agent-map .markers li:nth-child(1)
{
    top: 25%;
    left: 50%;
}

.agent-map .markers li:nth-child(2)
{
    top: 0%;
    left: 60%;
}

.agent-map .markers li:nth-child(3)
{
    top: 50%;
    left: 70%;
}

.agent-map .markers li:nth-child(4)
{
    top: 65%;
    left: 80%;
}

.agent-map .markers li:nth-child(5)
{
    top: 33%;
    left: 90%;
}

.agent-map .markers li:nth-child(6)
{
    top: 54%;
    left: 55%;
}

.btn-orange
{
    font-size: 1em;
    text-align: center;
    padding: 1em;
    color: #fff;
    margin-top: 1em;
}

.agent-meta .address
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
}

.agent-meta .rating-count
{
    display: none;
}

.agent-reviews
{
    padding: 0;
    list-style: none;
}

.agent-reviews .header
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: 'Lexend-Bold',Arial,Helvetica,sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 1em;
    text-align: center;
}

.agent-reviews .header .meta
{
    width: 90%;
    margin-bottom: 1em;
}

.agent-reviews .header .stars
{
    width: 90%;
    margin: 0;
}

.agent-reviews li
{
    margin-bottom: 2em;
}

[data-rating] .stars
{
    display: inline-block;
    width: 151px;
    height: 23px;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/stars.jpg") no-repeat 50% 0;
    vertical-align: middle;
    position: relative;
    margin-top: 0.5em;
}

[data-rating] .stars:before
{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: block;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/stars-active.jpg") no-repeat 0 0;
}


@media (min-width:699px) {
    .agent-reviews .header {
        flex-direction: row;
        text-align:left;
        
    }

    .agent-reviews .header .meta
    {
        width: 75%;
        margin: 0 20px 0 0;
    }

    .agent-reviews .header .stars
    {
        width: 25%;
        margin: 0;
    }

    [data-rating] .stars
    {
        background: url("/ResourcePackages/NAVL/assets/dist/img/template/stars.jpg") no-repeat 0 0;
    }

    [data-rating] .stars:before
    {
        left: 0;
        transform: translateX(0);
    }
}

[data-rating="0"] .stars:before
{
    width: 0;
}

[data-rating="0.5"] .stars:before
{
    width: 12px;
}

[data-rating="1"] .stars:before
{
    width: 24px;
}

[data-rating="1.5"] .stars:before
{
    width: 44px;
}

[data-rating="2"] .stars:before
{
    width: 56px;
}

[data-rating="2.5"] .stars:before
{
    width: 76px;
}

[data-rating="3"] .stars:before
{
    width: 88px;
}

[data-rating="3.5"] .stars:before
{
    width: 108px;
}

[data-rating="4"] .stars:before
{
    width: 120px;
}

[data-rating="4.5"] .stars:before
{
    width: 140px;
}

[data-rating="5"] .stars:before
{
    width: 152px;
}

.agent-about
{
    overflow: hidden;
    padding-bottom: 2em;
}

.agent-about .media
{
    margin-right: 1em;
    float: left;
}

.agent-about .media img
{
    width: 100%;
}

.agent-details
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 3px 0;
    border-top: solid 10px #ff1923;
    border-bottom: solid 1px #757575;
    margin-bottom: 30px;
}

.agent-details ul
{
    width: 45%;
    margin: auto 5% auto 0;
    padding: 0;
    list-style: none;
}

.agent-details .quick-form
{
    width: 50%;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.agent-details .quick-form .title
{
    margin: 0;
    width: 100%;
    text-align: center;
}

.agent-details .quick-form fieldset
{
    width: 47%;
    margin-top: 0.75em;
}

.agent-details .quick-form input
{
    margin: 0;
    width: 100%;
}

.agent-details .quick-form .footer
{
    display: none;
}

.agent-details .quick-form button
{
    margin: 30px auto 0;
}

.agent-review-form
{
    padding: 30px;
    background: #ebebeb;
    border: solid 1px #a7a9ac;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


.agent-review-form .title
{
    font-size: 2.333333333333333rem;
    line-height: 1.285714285714286em;
    margin: 0;
    width: 100%;
}

.agent-review-form fieldset
{
    width: 100%;
}

.agent-review-form .col
{
    width: 45.32374100719424%;
}

.agent-review-form input, .agent-review-form textarea
{
    width: 100%;
}

.agent-review-form textarea
{
    height: 160px;
}

.agent-review-form button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
    margin-top: 30px;
}

.agent-review-form button:hover
{
    border-color: #fff;
}

.agent-review-form [data-state] [for^='rating-']
{
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 41px;
    height: 38px;
    position: relative;
    display: inline-block;
    background: url("/ResourcePackages/NAVL/assets/dist/img/template/sprite-star.png") no-repeat 0 0;
    padding: 0;
    cursor: pointer;
    margin-right: 10px;
}

.agent-review-form [data-state] [for^='rating-']:hover
{
    background-position: 50% 0;
}

.agent-review-form [data-state] input
{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.agent-review-form [data-state='1'] [for='rating-1'], .agent-review-form [data-state='2'] [for='rating-1'], .agent-review-form [data-state='2'] [for='rating-2'], .agent-review-form [data-state='3'] [for='rating-1'], .agent-review-form [data-state='3'] [for='rating-2'], .agent-review-form [data-state='3'] [for='rating-3'], .agent-review-form [data-state='4'] [for='rating-1'], .agent-review-form [data-state='4'] [for='rating-2'], .agent-review-form [data-state='4'] [for='rating-3'], .agent-review-form [data-state='4'] [for='rating-4'], .agent-review-form [data-state='5'] [for='rating-1'], .agent-review-form [data-state='5'] [for='rating-2'], .agent-review-form [data-state='5'] [for='rating-3'], .agent-review-form [data-state='5'] [for='rating-4'], .agent-review-form [data-state='5'] [for='rating-5']
{
    background-position: 100% 0;
}

.page-moving-checklist .column-primary
{
    position: relative;
}

.page-moving-checklist aside.top
{
    position: absolute;
    top: -60px;
    right: 0;
}

.sfPageContainer .page-moving-checklist aside.top
{
    position: static;
    top: auto;
    right: auto;
}

.page-moving-checklist aside.bottom
{
    float: right;
}

.sfPageContainer .page-moving-checklist aside.bottom
{
    float: none;
}

.page-moving-checklist aside.bottom > a
{
    margin-bottom: 1em;
}

.page-moving-checklist .cta-download
{
    margin-top: 1em;
}

.checklist-form
{
    margin: 1em 0;
}

.checklist-form h3
{
    display: block;
    font-size: 1.333333333333333rem;
    background: #f2f2f2;
    padding: 0.625em 0.625em 0.625em 4.166666666666667em;
    position: relative;
    margin-bottom: 0.833333333333333em;
}

.checklist-form h3 img
{
    position: absolute;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    background: #fff;
    padding: 0 0.5em 0 0;
}

.checklist-form ul
{
    margin: 0 0 0 5.555555555555555rem;
    padding: 0;
    list-style: none;
}

.checklist-form ul li
{
    display: block;
    position: relative;
    padding-left: 2em;
    margin-bottom: 1em;
}

.checklist-form label
{
    font-size: 1rem;
    line-height: 1.388888888888889em;
    padding: 0;
}

.checklist-form input
{
    font-size: x-large;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #ff1923;
}

.checklist-form input:checked + span
{
    text-decoration: line-through;
}

.moving-companies-page ul.states
{
    padding-left: 35px;
    float: left;
    display: block;
    width: 158px;
    padding-bottom: 12px;
    margin-left: 0;
    line-height: 200%;
}

.moving-companies-page ul.states li
{
    color: #ff1923;
}

.moving-companies-page div.companies-list
{
    display: inline-block;
}

.navl-blog p.categories
{
    display: inline;
}

.navl-blog p.categories a:after
{
    content: ", ";
}

.navl-blog p.categories a:last-of-type:after
{
    content: "";
}


.navl-blog ul.share-icons-list
{
    display: inline-flex;
    float: right;
}

.navl-blog .post-info
{
    margin-bottom: 5px;
}

.navl-blog .pagination .active, .search-results .pagination .active
{
    font-size: 18px;
    padding: 0;
}

.navl-blog .pagination .sr-only, .search-results .pagination .sr-only
{
    display: none;
}

.navl-blog .sf-Comments-list img
{
    display: none;
}

.navl-blog .sf-Comments-header p.count
{
    font-size: 1.333333333333333rem;
}

.navl-blog [data-sf-role="comment-date"], .navl-blog [data-sf-role="comment-name"]
{
    font-size: 1.222222222222222rem;
}

.navl-blog [data-sf-role="comments-read-full-comment-button"]
{
    display: none;
}

.navl-blog.sidebar .comment-list, .navl-blog.sidebar .sf-Comments-header
{
    display: none;
}

.navl-blog.sidebar .comment-form
{
    margin-top: 1.666666666666667rem;
}

.navl-blog.sidebar .sf-Comments-captcha img
{
    display: block;
    margin-top: 10px;
}

.navl-blog.sidebar .sf-Comments-captcha input
{
    display: block !important;
}

.sf-Comments-form .has-error
{
    color: #ff1923;
}

.navl-blog.comments .comment-form
{
    display: none;
}

.sf-Comments-form .title
{
    font-family: 'Lexend-Regular', Arial, Helvetica, sans-serif;
    font-size: 1.166666666666667rem;
    margin-bottom: .5rem;
}

.sf-Comments-form input, .sf-Comments-form textarea
{
    border: 1px solid #a19f9f;
}

.sf-Comments-form textarea
{
    min-height: 150px;
}

.sf-Comments-form button
{
    background: #ff1923;
    color: #fff;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    margin-top: 1.5rem;
    padding: 0.239em 1.75em;
}

.page-blog h1
{
    margin-bottom: 1.666666666666667rem;
}

.page-blog h1 img
{
    width: 100%;
    height: auto;
    display: block;
}

.blog-logo img
{
    max-width: 100%;
}

.post, .sf-search-results .media
{
    border-bottom: solid 1px rgba(0,0,0,0.5);
    padding-bottom: 2.5rem;
    margin-bottom: 1.666666666666667rem;
}

.sf-search-results .media
{
    padding-bottom: .8rem;
}

.post .header
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 1.666666666666667rem;
}

.post h1
{
    color: inherit;
}

.post a
{
    color: #ff1923;
}

.post .meta
{
    width: 100%;
    margin: 0 0 0.555555555555556rem;
    display: block;
}

.post .date
{
    float: right;
}

.post .title
{
    margin: 0;
}

.post .title span
{
    color: #ff1923;
}

.post > img
{
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 1.666666666666667rem;
}

.post > p
{
    margin-bottom: 1.666666666666667rem;
}

.post .footer
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.post .footer p
{
    margin-bottom: 0;
}

.post:last-of-type
{
    border-bottom: none;
    padding-bottom: 0;
}

.post-comments .title
{
    font-size: 1.333333333333333rem;
    margin-bottom: 0.555555555555556em;
}

.post-comments .count
{
    font-size: 1.333333333333333rem;
    margin-bottom: 1.666666666666667em;
}

.post-comments ul
{
    padding: 0;
    list-style: none;
}

.post-comments .author, .post-comments li
{
    margin-bottom: 1.666666666666667rem;
}

.post-comments li:last-child
{
    margin-bottom: 0;
}

.post-comments .meta
{
    font-size: 1.222222222222222rem;
    margin-bottom: 0.227272727272727em;
    display: block;
}

.pagination
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 1em 0;
    padding: 0;
    list-style: none;
}

.pagination button
{
    font-size: 1.333333333333333rem;
    color: #ff1923;
    line-height: 1em;
    height: 1em;
    padding: 0.25em;
    box-sizing: content-box;
}

.pagination button:active, .pagination button:focus
{
    outline: none;
}

.pagination button:hover
{
    background: #d0d0d0;
}

.pagination .active
{
    color: #474747;
    cursor: default;
}

.pagination .active:hover
{
    background: inherit;
}

.pagination li
{
    margin: 0;
}

.pagination[data-short="first"] li:first-child, .pagination[data-short="last"] li:last-child
{
    display: none;
}

.k-pager-numbers, .k-pager-wrap, .pagination
{
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.k-pager-numbers .active, .k-pager-numbers .active a, .k-pager-numbers .k-state-selected, .k-pager-wrap .active, .k-pager-wrap .active a, .k-pager-wrap .k-state-selected, .pagination .active, .pagination .active a, .pagination .k-state-selected
{
    font-size: 1.333333333333333rem;
    color: black;
    line-height: 1.5;
    height: 1em;
    padding: 0.25em;
    box-sizing: content-box;
}

.k-pager-numbers a, .k-pager-wrap a, .pagination a
{
    font-size: 1.333333333333333rem;
    line-height: 1.5;
    height: 1em;
    padding: .25em;
    box-sizing: content-box;
    color: #ff1923;
}

.k-pager-numbers a:hover, .k-pager-wrap a:hover, .pagination a:hover
{
    background: #d0d0d0;
    text-decoration: none;
}

.k-pager-numbers li, .k-pager-wrap li, .pagination li
{
    padding: 0;
    box-sizing: border-box;
}

.k-pager-numbers .next, .k-pager-numbers .previous, .k-pager-wrap .next, .k-pager-wrap .previous, .pagination .next, .pagination .previous
{
    padding: 0;
}

.k-pager-numbers .next i, .k-pager-numbers .previous i, .k-pager-wrap .next i, .k-pager-wrap .previous i, .pagination .next i, .pagination .previous i
{
    font-style: normal;
}

.k-pager-numbers .next a, .k-pager-numbers .previous a, .k-pager-wrap .next a, .k-pager-wrap .previous a, .pagination .next a, .pagination .previous a
{
    color: #000;
}

.k-pager-numbers .previous, .k-pager-wrap .previous, .pagination .previous
{
    display: none;
}

.k-pager-numbers .next i, .k-pager-wrap .next i, .pagination .next i
{
    margin-left: 5px;
    margin-right: 10px;
}

.k-pager-numbers .previous i, .k-pager-wrap .previous i, .pagination .previous i
{
    margin-right: 5px;
    margin-left: 10px;
}

.k-current-page, .k-pager-first, .k-pager-info, .k-pager-last
{
    display: none;
}

.k-pager-nav:active, .k-pager-nav:focus
{
    outline: none;
}

.k-pager-nav.k-state-disabled
{
    display: none;
}

.k-pager-nav .k-i-arrow-e, .k-pager-nav .k-i-arrow-w
{
    line-height: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
}

.k-pager-nav .k-i-arrow-e:before, .k-pager-nav .k-i-arrow-w:before
{
    display: inline;
    vertical-align: top;
    width: 16px;
    height: 16px;
    color: #000;
    font-size: 16px;
}

.k-pager-nav .k-i-arrow-w:before
{
    content: "\25C0";
}

.k-pager-nav .k-i-arrow-w:before, .k-pager-nav .k-i-arrow-e:before
{
    font-size: 1.333333333333333rem;
    color: #ff1923;
    line-height: 1em;
    height: 1em;
    padding: 0.25em;
    box-sizing: content-box;
}

.k-pager-nav .k-i-arrow-e:before
{
    padding-left: 6px;
    content: "\25B6";
}

div[data-placeholder-label="sidebar"] .sfsearchBox
{
}

div.column-2ndary .sfsearchBox .sfsearchTxt
{
    border: 1px solid #a19f9f;
}

div.column-2ndary .sfsearchBox .sfsearchSubmit
{
    background: #ff1923;
    color: #fff;
    font-family: 'Lexend-Bold',Arial,Helvetica,sans-serif;
    margin-top: 1.5rem;
    padding: .05em 1.75em;
}

.form-blog-comments
{
    margin-top: 1.666666666666667rem;
}

.form-blog-comments input, .form-blog-comments textarea
{
    border: solid 1px #cfd0d1;
    width: 100%;
}

.form-blog-comments textarea
{
    margin: 0;
    height: 11.11111111111111rem;
}

.form-blog-comments button
{
    background: #ff1923;
    color: #fff;
    border: solid 2px #ff1923;
    padding: 0.239em 1.75em;
    font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    transition: border-color 0.5s ease-out 0s;
    margin: 1.666666666666667rem 0;
}

.form-blog-comments button:hover
{
    border-color: #fff;
}

.thanksLayout
{
    padding: 100px 0;
    background: #f7f7f7;
}

.thanksLayout-container
{
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

@media all and (-ms-high-contrast: none)
{
    *::-ms-backdrop, [data-section="form-full"] .form-wrapper .locations, #form-default .form-wrapper .locations
    {
        max-height: 400px;
    }

    [data-section="form-full"] .form-wrapper button
    {
        position: relative;
        z-index: 99;
    }

    *::-ms-backdrop, .agent-controls .search-form input
    {
        height: 41px;
    }
}

@media (max-width: 1315px)
{
    .quick-form .title
    {
        width: 100%;
        text-align: center;
        margin: 0 0 0.4921875em;
    }
}

@media (max-width: 1200px)
{
    .page-find-an-agent .agent-controls
    {
        position: relative;
        z-index: 2;
        width: 100%;
        background: #fff;
        margin: 0;
        border: solid 10px rgba(0,0,0,0.4);
        background-clip: padding-box;
    }

    .page-find-an-agent .agent-controls .header, .page-find-an-agent .agent-controls .footer
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
        padding: 0;
        width: 100%;
    }

    .page-find-an-agent .agent-controls h2
    {
        text-align: center;
    }

    .page-find-an-agent .agent-controls img
    {
        margin-bottom: 0.5em;
        width: 100%;
        max-width: 322px;
    }

    .page-find-an-agent .agent-controls .search-form
    {
        width: 100%;
        margin-bottom: 0.5em;
    }

    .page-find-an-agent .agent-controls .search-form fieldset
    {
        width: 100%;
    }

    .page-find-an-agent .agent-map
    {
        padding: 50px;
        overflow: hidden;
    }

    .page-find-an-agent .bg
    {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: auto;
        z-index: 1;
    }
}

@media (max-width: 1125px)
{
    .headline-with-search .search-form
    {
        position: relative;
        margin-bottom: 25px;
        width: 50%;
    }
}

@media (max-width: 1100px)
{
    .template-footer > ul
    {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .template-footer > ul > li
    {
        width: 25%;
    }

    .template-footer > ul > li:first-child
    {
        width: 100%;
        margin: 0 0 1em;
    }

    .template-footer > ul .social
    {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        -ms-grid-row-align: flex-end;
        align-items: flex-end;
    }

    .template-footer > ul .social li
    {
        padding: 0;
    }

    .template-footer > ul .social .cta-call
    {
        width: auto;
        margin: 0 auto 0 0;
    }

    .template-footer > ul .social .cta-linkedin
    {
        margin: 0;
    }

    .column-2ndary .quick-form
    {
        display: none;
    }

    .columns
    {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .columns .column-2ndary
    {
        width: 100%;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .columns .column-primary
    {
        width: 100%;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media (max-width: 1085px)
{
    .quick-form .fields button
    {
        margin: 1em auto 0;
    }
}

@media (max-width: 1000px)
{
    .main-navigation
    {
        padding: 20px;
    }

    .main-navigation nav > ul
    {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .main-navigation nav > ul > li
    {
        width: 50%;
    }

    .quick-form .title
    {
        width: 100%;
        text-align: center;
        margin: 0 0 0.4921875em;
    }

    .post .footer
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        -ms-grid-row-align: flex-start;
        align-items: flex-start;
    }

    .post .footer .categories
    {
        width: 100%;
        margin-bottom: 1em;
    }
}

@media (max-width: 950px)
{
    .reviewsLayout
    {
        padding: 50px 0 0;
    }

    .reviewsLayout-row
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
    }

    .reviewsLayout-row:before, .reviewsLayout-row:after
    {
        content: none;
    }

    .reviewsLayout-column
    {
        width: 100%;
        margin: 0 0 50px;
    }
}

@media (max-width: 915px)
{
    .quick-form
    {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .quick-form button
    {
        margin: 1em auto 0;
    }

    .quick-form input
    {
        width: 100%;
        margin: 0;
    }

    .quick-form fieldset
    {
        width: calc(50% - 9px);
    }

    .quick-form .fields
    {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .quick-form .fields fieldset
    {
        width: calc(33% - 9px);
    }

    .page-corporate .quick-form fieldset
    {
        width: calc(33.33333334% - 9px);
    }
}

@media (max-width: 900px)
{
    [data-section="form-full"] header, #form-default header
    {
        background: #fff;
        height: auto;
        padding-bottom: 25px;
    }

    [data-section="form-full"] header .heading, #form-default header .heading
    {
        width: 90%;
        max-width: 446px;
        margin: 0 auto;
        top: auto;
        left: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        font-size: 1.452222222222222rem;
    }

    [data-section="form-full"] header .heading img, #form-default header .heading img
    {
        width: 100%;
    }

    [data-section="form-preview"]
    {
        background: url("/ResourcePackages/NAVL/assets/dist/img/temporary/bg-home-hero-small.jpg") no-repeat 50% 100%;
        background-size: cover;
        min-height: inherit;
        padding: 50px 0;
    }

    [data-section="form-preview"] .content
    {
        display: block;
        min-height: 0;
        padding-top: 0;
    }

    [data-section="form-preview"] .content .home-form
    {
        width: auto;
    }

    [data-section="form-preview"] .content .home-form .headline-3
    {
        width: 100%;
    }

    [data-section="form-preview"]:before, [data-section="form-preview"]:after
    {
        content: none;
    }

    [data-section="form-preview"] .content
    {
        text-align: center;
    }

    [data-section="form-preview"] p, [data-section="form-preview"] h1, [data-section="form-preview"] h2, [data-section="form-preview"] form
    {
        width: auto;
    }

    [data-section="form-preview"] .headline-1
    {
        display: none;
        font-size: 1.120555555555556rem;
        line-height: 1em;
    }

    [data-section="form-preview"] .headline-2
    {
        display: none;
        font-size: 1.882777777777778rem;
        line-height: 1em;
    }

    [data-section="form-preview"] .headline-3
    {
        font-size: 1.568888888888889rem;
        line-height: 1em;
        margin-bottom: inherit;
    }

    [data-section="form-preview"] form, [data-section="form-preview"] .home-form
    {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 1em;
    }

    [data-section="form-preview"] fieldset
    {
        width: 49%;
    }

    [data-section="form-preview"] input
    {
        width: 100%;
    }

    [data-section="form-preview"] button
    {
        margin: 1em auto 0;
    }

    [data-section="types"]
    {
        margin: 50px auto;
    }

    [data-section="types"] ul
    {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    [data-section="types"] li
    {
        width: 48%;
        padding-bottom: 0.5em;
    }

    [data-section^="resources"] li
    {
        width: 47.7%;
    }

    [data-section^="resources"] ul a
    {
        font-size: 0.555555555555556rem;
    }

    [data-section^="resources"] ul a strong
    {
        font-size: 1rem;
        line-height: 1em;
    }
}

@media (max-width: 768px)
{
    body
    {
        padding-top: 50px;
        min-width: 0px;
    }

    .sf-lang-selector
    {
        display: none;
    }

    .main-header
    {
        height: 50px;
    }

    .main-header .toggle
    {
        width: 50px;
        font-size: 0.777777777777778rem;
        line-height: 50px;
    }



    .main-header img.logo
    {
        height: 49px;
        width: auto;
    }

    .main-header [href^="tel:"]
    {
        font-size: 1.166666666666667rem;
        padding: 0;
        margin: auto 10px auto auto !important;
        height: auto;
    }

    .main-header [href^="tel:"] span
    {
        font-size: 0.714285714285714em;
    }

    .main-header [href^="tel:"]:after
    {
        content: none;
    }

    .main-header .btn-go-back
    {
        padding: 10px 10px 0 20px;
        font-size: 0.777777777777778rem;
        line-height: 1.142857142857143em;
        background-size: auto 20px, auto 100%;
        background-position: 5px 50%, 0px 50%;
        width: 50px;
    }

    .main-header .btn-quote
    {
        font-size: 0.777777777777778rem;
        width: 50px;
    }

    .main-navigation
    {
        top: 50px;
    }

    .main-content .quick-form
    {
        display: none;
    }

    .template-footer > ul > li
    {
        width: 50%;
        margin: 0 0 1em;
    }

    [data-section="form-full"] .move-size .sizes .tip, #form-default .move-size .sizes .tip
    {
        font-size: 1.333333333333333rem;
    }

    [data-section="form-full"] .form-wrapper form > *, #form-default .form-wrapper form > *
    {
        padding: 20px 20px;
        box-sizing: border-box;
    }

    [data-section="form-full"] .form-wrapper .quote-carousel
    {
        padding: 0;
    }

    [data-section="form-full"] .form-wrapper .quote-carousel fieldset.move-size
    {
        padding: 20px;
        box-sizing: border-box;
    }

    .sidebar-menu .title
    {
        padding-bottom: 0;
        font-size: 1.777777777777778rem;
        line-height: 0.9375em;
        font-family: 'Lexend-Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        padding: 0 0 0.625em 0;
        margin: 0;
        text-transform: uppercase;
    }

    .sidebar-menu ul
    {
        margin: 0 auto;
    }

    .featured-articles > p
    {
        width: 100%;
    }

    .featured-articles ul
    {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .featured-articles ul li
    {
        width: 100%;
        margin-bottom: 25px;
    }

    .featured-articles ul li:last-child
    {
        margin-bottom: 0;
    }

    .page-find-an-agent .agent-controls
    {
        padding: 10px;
    }

    .page-find-an-agent .agent-map
    {
        padding: 25px;
    }

    .agent-meta .address
    {
        font-size: 0.888888888888889rem;
        line-height: 1.5625em;
    }

    .agent-about .media
    {
        display: none;
    }

    .agent-details
    {
        padding: 0;
        border: none;
    }

    .agent-details ul
    {
        width: 100%;
        margin: 0;
    }

    .agent-details .quick-form
    {
        display: none;
    }

    .agent-review-form
    {
        padding: 20px;
    }

    .agent-review-form .col
    {
        width: 100%;
    }

    .agent-review-form button
    {
        margin: 20px auto 0;
    }

    .page-agent-profile
    {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .page-agent-profile > *
    {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }

    .page-agent-profile h1
    {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .page-agent-profile .map-wrapper
    {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }

    .page-agent-profile .agent-details
    {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .agent-details .rating-count
    {
        display: none;
    }

    .page-moving-checklist aside.top, .page-moving-checklist aside.bottom
    {
        float: none;
        position: static;
        top: auto;
        right: auto;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 1em 0;
    }
}

@media (max-width: 767px)
{
    .main-navigation nav > ul
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .main-navigation nav > ul > li
    {
        width: 100%;
    }

    .page-corporate .hero, .page-moving-checklist .hero, .page-international .hero, .page-household .hero
    {
        padding: 50px 0;
    }
}

@media (max-width: 700px)
{
    .agent-map .agent-results
    {
        position: relative;
        top: auto;
        left: auto;
        height: auto;
        width: 100%;
        background: transparent;
        padding: 0 30px;
    }

    .agent-map .agent-results .search-results
    {
        padding-left: 0;
        position: relative;
        top: auto;
        bottom: 0;
    }

    .agent-map .agent-results form
    {
        position: static;
        top: auto;
        width: 100%;
        padding: 0;
    }

    .agent-map .agent-results ul
    {
        padding: 1em 0;
    }

    .markers
    {
        display: none;
    }

    .page-find-an-agent-results .agent-controls
    {
        position: static;
        top: auto;
    }

    .main-navigation nav > ul > li
    {
        min-width: 175px;
    }
}

@media (max-width: 600px)
{
    .quick-form .title
    {
        font-size: 1.555555555555556rem;
    }

    .quick-form .footer
    {
        font-size: 0.888888888888889rem;
    }

    .quick-form button
    {
        margin: 0 auto;
    }

    .quick-form fieldset
    {
        width: 100%;
        margin: 0 0 1em;
    }

    .quick-form .fields fieldset
    {
        width: 100%;
        margin: 0 0 1em;
    }

    .headline-with-search .search-form
    {
        width: auto;
    }
}

@media (max-width: 599px)
{
    .main-navigation
    {
        width: 90%;
        height: auto;
        max-height: calc(100% - 50px);
        overflow-y: auto;
    }

    .main-content
    {
        border-top-width: 4px;
        border-bottom-width: 5px;
    }

    .template-footer
    {
        padding: 0;
    }

    .template-footer > ul
    {
        width: 100%;
    }

    .template-footer > ul > li
    {
        width: 100%;
        margin: 0;
        padding: 20px;
        box-sizing: border-box;
        border-top: #e1e1e1 solid 1px;
        border-right: #e1e1e1 solid 1px;
        border-left: #e1e1e1 solid 1px;
        background: linear-gradient(to bottom, #fefefe 0%, #e1e1e1 100%);
    }

    .template-footer > ul > li:last-child
    {
        border-bottom: #e1e1e1 solid 1px;
    }

    .template-footer > ul > li:first-child
    {
        margin: 0;
        background: #fff;
    }

    .template-footer .social li.cta-call
    {
        font-size: 1.555555555555556rem;
    }

    .template-footer .social li.cta-call span
    {
        font-size: 0.785714285714286em;
    }

    .template-footer li li
    {
        padding: 0;
    }

    .template-footer li:nth-child(n+2) li:nth-child(n+2)
    {
        display: none;
    }

    .template-footer strong
    {
        width: 100%;
        font-size: 1.555555555555556rem;
    }

    .template-footer strong:after
    {
        content: "+";
        float: right;
    }

    .template-footer + small
    {
        padding: 1em 0;
    }

    .template-footer [data-state='open'] strong:after
    {
        content: "-";
    }

    .page-corporate-form .col, .page-international-form .col
    {
        width: 100%;
    }


    [data-section="form-full"] .col, #form-default .col, .ScholarWrapper .col
    {
        width: 100%;
    }

    [data-section="form-full"] .move-size .headline, #form-default .move-size .headline, [data-section="form-full"] .move-size label[for="size-selection"], #form-default .move-size label[for="size-selection"]
    {
        font-size: 1.666666666666667rem;
        line-height: 1em;
        width: 90%;
        margin: 0 auto;
    }

    [data-section="form-full"] .move-size .sizes .tip, #form-default .move-size .sizes .tip
    {
        position: absolute;
        top: 0;
        width: 300%;
        box-sizing: border-box;
    }

    [data-section="form-full"] .move-size .sizes strong, #form-default .move-size .sizes strong
    {
        font-size: 14px;
        line-height: 120px;
    }

    [data-section="form-full"] .move-size .sizes label, #form-default .move-size .sizes label
    {
        padding-top: 120px;
    }

    [data-section="form-full"] .move-size .sizes label[for="small"] .tip, #form-default .move-size .sizes label[for="small"] .tip
    {
        left: 0;
    }

    [data-section="form-full"] .move-size .sizes label[for="small"] .tip:after, #form-default .move-size .sizes label[for="small"] .tip:after
    {
        left: 13%;
    }

    [data-section="form-full"] .move-size .sizes label[for="small"] svg, #form-default .move-size .sizes label[for="small"] svg
    {
        width: 50%;
    }

    [data-section="form-full"] .move-size .sizes label[for="small"] strong, #form-default .move-size .sizes label[for="small"] strong
    {
        font-size: 14px;
        line-height: 120px;
    }

    [data-section="form-full"] .move-size .sizes label[for="medium"] .tip, #form-default .move-size .sizes label[for="medium"] .tip
    {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    [data-section="form-full"] .move-size .sizes label[for="medium"] .tip:after, #form-default .move-size .sizes label[for="medium"] .tip:after
    {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    [data-section="form-full"] .move-size .sizes label[for="medium"] svg, #form-default .move-size .sizes label[for="medium"] svg
    {
        width: 75%;
    }

    [data-section="form-full"] .move-size .sizes label[for="large"] .tip, #form-default .move-size .sizes label[for="large"] .tip
    {
        right: 0;
    }

    [data-section="form-full"] .move-size .sizes label[for="large"] .tip:after, #form-default .move-size .sizes label[for="large"] .tip:after
    {
        left: 79%;
    }

    [data-section="form-full"] .move-size .sizes label[for="large"] svg, #form-default .move-size .sizes label[for="large"] svg
    {
        width: 100%;
    }

    [data-section="form-preview"] form
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    [data-section="form-preview"] fieldset
    {
        width: 100%;
    }

    [data-section="scroll arrow"]
    {
        display: none;
    }

    [data-section="types"] ul
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    [data-section="types"] li
    {
        width: 100%;
    }

    [data-section^="resources"] picture
    {
        width: 40%;
    }

    [data-section^="resources"] picture img
    {
        width: 90%;
    }

    .column, .columns
    {
        margin: 20px auto;
    }

    .page-corporate .hero fieldset, .page-international .hero fieldset, .hero .fields-default fieldset, .hero .intl-fields fieldset
    {
        width: 100%;
    }

    .breadcrumbs
    {
        margin: 20px auto;
    }

    .page-find-an-agent .agent-map
    {
        padding: 10px;
    }



    .page-moving-checklist aside.top, .page-moving-checklist aside.bottom
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
    }

    .checklist-form
    {
        padding-left: 0;
    }

    .checklist-form h3
    {
        padding: 5.5em 0 0 0;
        background: none;
    }

    .checklist-form h3 img
    {
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        padding: 0;
        width: 125px;
        height: auto;
        background: none;
    }

    .checklist-form ul
    {
        margin: 0;
    }

    .navl-blog ul.share-icons-list
    {
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
        float: none;
    }
}

@media (max-width: 499px)
{
    .template-thanks .main-header .btn-go-back
    {
        width: 25px;
    }
}

@media (max-width: 480px)
{
    .template-footer .social li.cta-call
    {
        font-size: 1.222222222222222rem;
    }

    .template-footer .social li.cta-call span
    {
        font-size: 0.818181818181818em;
    }

    .template-footer strong
    {
        font-size: 1rem;
    }

    .agents-list li
    {
        width: 100%;
    }
}

@media (max-width: 414px)
{
    [data-section^="resources"] ul a span
    {
        width: calc(65% - 10px);
    }

    [data-section^="resources"] picture
    {
        width: 35%;
    }

    [data-section^="resources"] picture img
    {
        width: 90%;
    }
}

@media (max-width: 400px)
{
    .hidden-above-400
    {
        display: block;
    }

    h1, h2
    {
        font-size: 1.333333333333333rem;
        line-height: 1.25em;
        margin-bottom: 0.625em;
    }

    h4
    {
        font-size: 1.166666666666667rem;
        line-height: 1.238095238095238em;
    }

    .main-header .btn-quote
    {
        font-size: 0.666666666666667rem;
    }

    .main-header [href^="tel:"]
    {
        font-size: 0.666666666666667rem;
        line-height: 1em;
    }

    .main-header [href^="tel:"] span
    {
        font-size: 0.833333333333333em;
    }

    [data-section^="resources"] ul a strong
    {
        font-size: 0.777777777777778rem;
        line-height: 1em;
    }

    .reviewCallout-img img
    {
        height: 100px;
        width: 100px;
    }

    .hero h2
    {
        font-size: 1.333333333333333rem;
        line-height: 1.25em;
    }

    .hero p
    {
        font-size: 1rem;
        line-height: 1.333333333333333em;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto 0.75em;
    }
}

@media (max-width: 370px)
{
    [data-section^="resources"] li
    {
        width: 100%;
        margin: 5px auto;
    }

    [data-section^="resources"] picture img
    {
        width: 60%;
    }
}

@-webkit-keyframes cloudy-movement
{
    0%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100%
    {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes cloudy-movement
{
    0%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100%
    {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

/* css agent local resources */
.loc-res, .ser-offer
{
    float: left;
    width: 100%;
}

.loc-res ul
{
    padding-left: 0px;
}

.loc-res ul li
{
    list-style: none;
    float: left;
    width: 300px;
}

.loc-res ul li a
{
    color: #105CB6;
}

.ser-offer-list
{
    float: left;
    width: 250px;
    margin-bottom: 10px;
}

.ser-offer-list img
{
    vertical-align: middle;
    margin-bottom: 10px;
}

.ser-offer-list span
{
    font-size: 1.8rem;
    padding-left: 4px;
}

.noAgentLocalList
{
    width: 100% !important;
}

@media only screen and (max-width: 480px)
{
    .loc-res, .ser-offer
    {
        width: 100%;
    }

    .loc-res ul li
    {
        width: 100%;
    }

    .city-agent-near-you .ser-offer h3
    {
        margin-top: 20px;
    }
}

/* css agent near by city */
.city-agent-near-you h3 span
{
    text-transform: capitalize;
}

ul.agents-city-list
{
    float: left;
    margin-bottom: 35px;
}

ul.agents-city-list li
{
    float: left;
    list-style: none;
    width: 200px;
    padding: 0 15px 20px 50px;
    border-right: 1px solid #ff1923;
}

ul.agents-city-list li a
{
    color: #474747;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    background: url("/ResourcePackages/NAVL/assets/dist/img/icons/Northern-List-Icon.png") no-repeat;
    padding-left: 28px;
    padding-top: 2px;
}

.check-list li
{
    background: url(../img/bullet-chk-mrk.gif) left 3px no-repeat;
    padding-left: 20px;
    list-style: none;
}

.check-list
{
    margin-bottom: 30px;
}

.pac-container
{
    z-index: 1051 !important;
}

.page-agent-profile .agent-reviews .meta
{
    font-family: UniversLTPro,Arial,Helvetica,sans-serif;
}

.page-agent-profile .agent-reviews h2
{
    font-weight: 300;
}


html[lang=fr] .sfPage-formulaire-d’-estimè-gratuity .language-selector,
html[lang=fr] .sfPage-international-estimation .language-selector,
html[lang=fr] .sfPage-free-quote .language-selector,
html[lang=fr] .sfPage-fr-corporate-quote .language-selector,
html[lang=fr] .sfPage-fr-international-quote .language-selector,
html[lang=fr] .sfPage-corporatif-estimation .language-selector {
    right: 236px;
}

