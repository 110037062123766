[data-section="navigation-resources"]
{
    background: transparent;
    padding: 50px 0;
    margin: 0 auto;
}

[data-section="navigation-resources"] p
{
    width: 100%;
    font-size: 40px;
    line-height: 48px;
    font-family: "Light Cond", sans-serif;
    text-transform: uppercase;
    color: #666666;
    text-align: center;
    padding-bottom: 50px;
}

[data-section="navigation-resources"] ul
{
    width: 75%;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1076px;
}

[data-section="navigation-resources"] ul li
{
    width: 30.89908257%;
    margin: 25px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row no-wrap;
    flex-flow: row no-wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

[data-section="navigation-resources"] ul li a
{
    width: 100%;
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    font-family: "Light Cond", sans-serif;
    color: #000000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 25px;
    color: #FF3333;
    padding: 15px;
}

[data-section="navigation-resources"] ul li a picture
{
    text-align: center;
    margin: 10px 20px;
}

[data-section="navigation-resources"] ul li a picture img
{
    height: auto;
    width: auto;
}

[data-section="navigation-resources"] ul li a span
{
    margin-left: 15px;
    width: 57.62711864%;
}

[data-section="navigation-resources"] ul li a span strong
{
    font-size: 22px;
    line-height: 28px;
    font-family: "Bold Cond", sans-serif;
    color: #006699;
    display: block;
    text-transform: uppercase;
    font-weight:400;  /*<-- Yeah, I know */
}

[data-section="navigation-resources"] ul li a:hover
{
    border: #FF3333 solid 6px;
    text-decoration: none;
    padding: 9px;
}

[data-section="navigation-resources"] .see-all-link
{
    margin: 0 auto;
    text-align: center;
}

[data-section="navigation-resources"] .see-all-link a
{
    font-family: "Bold Cond", sans-serif;
    font-size: 24px;
    line-height: 14px;
    color: #ffffff;
    background: #FF3333;
    border: 1px solid #FF3333;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    padding: 15px 50px;
}

[data-section="navigation-resources"] .see-all-link a:hover
{
    text-decoration: none;
}

@media only screen and (max-width: 768px)
{
    [data-section="navigation-resources"]
    {
        padding-bottom: 0px;
        padding-top: 25px;
    }

    [data-section="navigation-resources"] p
    {
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    [data-section="navigation-resources"] ul
    {
        width: 95%;
    }

    [data-section="navigation-resources"] ul li
    {
        width: 95%;
        margin: 0;
    }
}
