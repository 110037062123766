
.full-width-cta
{
    position: relative;
}

.full-width-cta .image-background
{
    background-color: #fff;
    width: 100%;
}

.full-width-cta .content
{
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 25px 15px;
    box-sizing: border-box;
}

.full-width-cta .h2,
.full-width-cta h2
{
    font-size: 36px;
    line-height: 48px;
    font-family: "Light Cond", sans-serif;
    text-transform: uppercase;
    color: #666666;
}

.full-width-cta p
{
    font-size: 20px;
    line-height: 33px;
    font-family: "Light Cond", sans-serif;
    color: #000000;
    padding: 10px 0;
}

.full-width-cta .btn-primary,
.full-width-cta .btn-primary:hover,
.full-width-cta .btn-primary:active,
.full-width-cta .btn-primary:focus
{
    background: #FF3333;
    border: 1px solid #FF3333;
    border-radius: 10px;
    padding: 15px;
    font-family: "Bold Cond", sans-serif;
    font-size: 24px;
    line-height: 14px;
    color: #ffffff;
    display: inline-block;
    text-decoration:none;
    cursor:pointer;
}


@media only screen and (min-width : 768px)
{
    .full-width-cta .image-background
    {
        background-position: center center;
        background-size: cover;
        height: 900px;
        background-image: inherit;
    }


    .full-width-cta .content
    {
        width: 40%;
        left: calc(60% - 25px);
        position: absolute;
        background-color: #fff;
        padding: 50px 25px;
        box-sizing: border-box;
        top: 50%;
        transform: translateY(-50%);
        max-width: 825px;
        border-radius: 25px;
    }
}
