
.instaquote-container {
    background-image: url("/ResourcePackages/NAVL/assets/dist/img/template/homepage-herobanner_small.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 45% 50%;
    margin: 0 auto;
}

    .instaquote-container.thanks {
        background-position: bottom left;
        min-height: 440px;
        padding: 100px 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-direction: column;
        flex-direction: column;
    }

        .instaquote-container.thanks * {
            width: 90%;
            max-width: 650px;
        }

    .instaquote-container .quote-content,
    .instaquote-container.local-move .local-thanks {
        display: block;
    }

    .instaquote-container.local-move .quote-content,
    .instaquote-container .local-thanks {
        display: none;
    }

    .instaquote-container .local-thanks {
        margin: 100px auto;
    }


    .instaquote-container .carousel-box {
        overflow-x: hidden;
        width: 100%;
    }

        .instaquote-container .carousel-box form {
            width: 500%;
            display: -ms-flexbox;
            display: flex;
            padding: 25px 0;
        }

            .instaquote-container .carousel-box form [data-step] {
                position: relative;
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                width: 20%;
            }

                .instaquote-container .carousel-box form [data-step] [data-controller="-"] {
                    position: relative;
                }

                .instaquote-container .carousel-box form [data-step] .btn-tertiary {
                    font-family: "Bold Cond", sans-serif;
                    font-size: 24px;
                    line-height: 14px;
                    color: #ffffff;
                    background: #FF3333;
                    border: 1px solid #FF3333;
                    border-radius: 10px;
                    padding: 15px;
                    text-transform: uppercase;
                    margin: 10px;
                    -ms-flex-item-align: left;
                    -ms-grid-row-align: left;
                    align-self: left;
                }

                    .instaquote-container .carousel-box form [data-step] .btn-tertiary:focus {
                        outline: none;
                    }

                .instaquote-container .carousel-box form [data-step] .head4 {
                    font-size: 25px;
                    line-height: 25px;
                    font-family: "Light Cond", sans-serif;
                    text-align: center;
                    color: #ffffff;
                    margin-bottom: 0;
                }

            .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper {
                margin: 0 auto;
                background: #006699;
                border: 1px solid #006699;
                border-radius: 10px;
                padding: 30px 15px;
                max-width: 694px;
                width: calc(100vw - 35px);
            }

                .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper legend {
                    margin: 0 auto;
                    width: 100%;
                }

                    .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper legend img {
                        width: 100%;
                        height: auto;
                    }

                .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .inner-container {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
                    background: #ffffff;
                    border-radius: 10px;
                    margin: 15px;
                }

                    .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .inner-container fieldset {
                        width: 95%;
                        padding: 5px 10px;
                        margin: 0;
                    }

                        .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .inner-container fieldset label {
                            font-size: 30px;
                            line-height: 36px;
                            font-family: "Bold Cond", sans-serif;
                            color: #FF3333;
                            padding-bottom: 0;
                        }

                        .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .inner-container fieldset input {
                            width: 100%;
                            background: #F2F2F2;
                            background: #e0dfdf;
                            border-radius: 7px;
                            font-size: 20px;
                            line-height: 22px;
                            height: 37px;
                            color: #666666;
                            padding: 8px;
                        }

                    .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .inner-container button {
                        font-family: "Bold Cond", sans-serif;
                        font-size: 24px;
                        line-height: 14px;
                        color: #ffffff;
                        background: #FF3333;
                        border: 1px solid #FF3333;
                        border-radius: 10px;
                        padding: 15px;
                        text-transform: none;
                        margin: 20px;
                        -ms-flex-item-align: left;
                        -ms-grid-row-align: left;
                        align-self: left;
                    }

                        .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .inner-container button:focus {
                            outline: none;
                        }

                .instaquote-container .carousel-box form [data-step="1"] .step-1-wrapper .head3 {
                    font-size: 22px;
                    line-height: 25px;
                    font-family: "Light Cond", sans-serif;
                    color: #ffffff;
                    width: 80%;
                    text-align: center;
                    margin: 0 auto;
                    padding: 15px 0;
                }

            .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper {
                margin: 0 auto;
                background: #006699;
                border: 1px solid #006699;
                border-radius: 10px;
                padding: 15px;
                max-width: 700px;
            }

                .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .head1 {
                    text-align: center;
                    color: #ffffff;
                    font-family: "Light Cond", sans-serif;
                    font-size: 52px;
                    padding: 20px 0;
                }

                .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker {
                    margin: 0 auto;
                    background: none;
                    border: none;
                    color: #ffffff;
                }

                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-header .ui-datepicker-title {
                        font-family: "Light Cond", sans-serif;
                        color: #ffffff;
                        text-transform: uppercase;
                    }

                        .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-header .ui-datepicker-title span {
                            font-size: 24px;
                            line-height: 28px;
                        }

                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar {
                        font-family: "Light Cond", sans-serif;
                        color: #006699;
                        background: #ffffff;
                        border-radius: 10px;
                    }

                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-state-active {
                        background: #ff3333;
                        color: #ffffff;
                    }

                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar thead tr th,
                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar tbody tr td {
                        border: none;
                    }

                        .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-unselectable,
                        .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar tbody tr td.ui-datepicker-today {
                            background: none;
                        }

                            .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar tbody tr td.ui-datepicker-today a {
                                color: #ffffff;
                            }

                        .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar thead tr th span {
                            font-size: 24px;
                        }

                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar tr td span,
                    .instaquote-container .carousel-box form [data-step="2"] .step-2-wrapper .ui-datepicker .ui-datepicker-group .ui-datepicker-calendar tr td a {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                    }

            .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper {
                margin: 0 auto;
                background: #006699;
                border: 1px solid #006699;
                border-radius: 10px;
                padding: 15px;
                max-width: 750px;
            }

                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .head1 {
                    text-align: center;
                    color: #ffffff;
                    font-family: "Light Cond", sans-serif;
                    font-size: 44px;
                    padding: 30px 0;
                }

                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
                    background: #ffffff;
                    border-radius: 10px;
                    margin: 10px 30px 15px;
                    padding: 25px 0;
                }

                    .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div {
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-direction: row;
                        flex-direction: row;
                    }

                        .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] {
                            -ms-flex-pack: center;
                            justify-content: center;
                            width: 100%;
                        }

                            .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label {
                                width: 175px;
                                height: 175px;
                                margin: 0 75px;
                                text-align: center;
                                position: relative;
                                cursor: pointer;
                            }

                                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label input {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                }

                                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label span {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background-repeat: no-repeat;
                                    padding-top: 140px;
                                    box-sizing: border-box;
                                    font-size: 25px;
                                    color: #006699;
                                }

                                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label svg {
                                    position: absolute;
                                    bottom: 110px;
                                    left: 50%;
                                    z-index: 5;
                                    -ms-transform: translate(-50%, 50%);
                                    transform: translate(-50%, 50%);
                                    opacity: 1;
                                    z-index: 1;
                                }

                                    .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label svg path {
                                        fill: #006699;
                                    }

                                    .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label svg polygon {
                                        fill: #006699;
                                    }

                                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label input:checked ~ span {
                                    color: #FF3333;
                                }

                                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label input:checked ~ svg polygon {
                                    fill: #FF3333;
                                }

                                .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="type"] label input:checked ~ svg path {
                                    fill: #FF3333;
                                }

                        .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="rooms"] {
                            -ms-flex-pack: distribute;
                            justify-content: space-around;
                            margin: 0 auto;
                            width: 100%;
                            margin-top: 25px;
                        }

    .instaquote-container .selection-wrapper .homeSizeGrouping {
        display: none !important;
    }

        .instaquote-container .selection-wrapper .homeSizeGrouping.showroomzise {
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-direction: row !important;
            flex-direction: row !important;
        }

    .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="rooms"] label {
        margin: 0;
        width: auto;
    }

        .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="rooms"] label span {
            background: transparent;
            display: inline-block;
            text-align: center;
            font-family: "Light Cond", sans-serif;
            color: #006699;
            font-size: 24px;
            line-height: 30px;
            padding: 10px;
            cursor: pointer;
        }

        .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="rooms"] label input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

            .instaquote-container .carousel-box form [data-step="3"] .step-3-wrapper .selection-wrapper div[data-size="rooms"] label input:checked + span {
                background: #FF3333;
                color: white;
                border-radius: 10px;
                line-height: 24px;
                cursor: pointer;
            }

    .instaquote-container .carousel-box form [data-step="3"][data-state="2"] .head1:after {
        content: "current apartment type.";
    }

    .instaquote-container .carousel-box form [data-step="3"][data-state="2"] [data-size="rooms"] label:first-child span:before {
        content: "Studio / ";
    }

    .instaquote-container .carousel-box form [data-step="3"][data-state="3"] .head1:after {
        content: "current house type.";
    }

    .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper {
        margin: 0 auto;
        background: #006699;
        border: 1px solid #006699;
        border-radius: 10px;
        padding: 15px;
        max-width: 525px;
    }

        .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .head1 {
            text-align: center;
            color: #ffffff;
            font-family: "Light Cond", sans-serif;
            font-size: 45px;
            padding: 25px 0;
        }

        .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .inner-container {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
            background: #ffffff;
            border-radius: 10px;
            margin: 15px;
            margin-bottom: 25px;
        }

            .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .inner-container fieldset {
                width: 95%;
                margin: 0;
            }

                .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .inner-container fieldset label {
                    font-size: 30px;
                    line-height: 36px;
                    font-family: "Bold Cond", sans-serif;
                    color: #FF3333;
                    padding-bottom: 0;
                }

                .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .inner-container fieldset input {
                    width: 100%;
                    background: #F2F2F2;
                    border-radius: 7px;
                    font-size: 20px;
                    line-height: 22px;
                    height: 50px;
                    color: #999999;
                    padding: 10px;
                }

            .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .inner-container button {
                font-family: "Bold Cond", sans-serif;
                font-size: 24px;
                line-height: 14px;
                color: #ffffff;
                background: #FF3333;
                border: 1px solid #FF3333;
                border-radius: 10px;
                padding: 15px;
                text-transform: none;
                margin: 20px;
                -ms-flex-item-align: left;
                -ms-grid-row-align: left;
                align-self: left;
            }

                .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .inner-container button:focus {
                    outline: none;
                }

        .instaquote-container .carousel-box form [data-step="4"] .step-4-wrapper .legal {
            color: #ffffff;
            font-family: "Light Cond", sans-serif;
            font-size: 0.6em;
            text-align: justify;
        }

    .instaquote-container .carousel-box form [data-step="5"] {
        text-align: center;
    }

        .instaquote-container .carousel-box form [data-step="5"] .head5 {
            font-size: 20px;
            line-height: 25px;
            font-family: "Light Cond", sans-serif;
            text-align: center;
            color: #006699;
        }

            .instaquote-container .carousel-box form [data-step="5"] .head5 span {
                display: inline-block;
                font-size: 20px;
                line-height: 25px;
                font-family: "Light Cond", sans-serif;
                text-align: center;
                color: #006699;
            }

            .instaquote-container .carousel-box form [data-step="5"] .head5 a {
                font-size: 20px;
                line-height: 25px;
                font-family: "Light Cond", sans-serif;
                color: #FF3333;
                padding-top: 10px;
            }

                .instaquote-container .carousel-box form [data-step="5"] .head5 a:hover {
                    text-decoration: underline;
                }

        .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper {
            margin: 0 auto;
            background: #006699;
            border: 1px solid #006699;
            border-radius: 10px;
            padding: 15px;
            max-width: 60%;
            margin-top: 25px;
        }

            .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper .head1 {
                text-align: center;
                color: #ffffff;
                font-family: "Light Cond", sans-serif;
                font-size: 45px;
                padding: 35px 0;
                line-height: 40px;
            }

            .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper .head6 {
                text-align: center;
                color: #ffffff;
                font-family: "Light Cond", sans-serif;
                font-size: 32px;
                padding-bottom: 40px;
            }

            .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-pack: center;
                justify-content: center;
                width: 100%;
                max-width: 900px;
                margin: 0 auto;
                background: #ffffff;
                border-radius: 10px;
                padding-bottom: 25px;
            }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label {
                    width: 33.33333333%;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    cursor: pointer;
                    text-align: center;
                    position: relative;
                    color: #006699;
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] .tip {
                    position: relative;
                    display: inline-block;
                    border-radius: 25px;
                    color: #ffffff;
                    background: #FF3333;
                    text-align: center;
                    padding: 0.5em;
                    -ms-flex-order: 1;
                    order: 1;
                    opacity: 0;
                    transition: all 0.5s ease-in-out 0s;
                    width: 90%;
                    box-sizing: border-box;
                    -ms-transform: translateY(10px);
                    transform: translateY(10px);
                    font-size: 22px;
                    margin: 0 auto;
                    margin-top: 25px;
                    margin-bottom: 25px;
                }

                    .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] .tip:after {
                        content: '';
                        height: 0;
                        width: 0;
                        border-top: solid 20px #FF3333;
                        border-right: solid 10px transparent;
                        border-bottom: transparent;
                        border-left: solid 10px transparent;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        -ms-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    -ms-flex-order: 3;
                    order: 3;
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] strong {
                    -ms-flex-order: 2;
                    order: 2;
                    text-align: center;
                    color: #006699;
                    display: block;
                    width: 100%;
                    position: relative;
                    z-index: 5;
                    font-family: "Light Cond", sans-serif;
                    font-size: 30px;
                    padding: 150px 0 0;
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] input:checked ~ .tip {
                    opacity: 1;
                    -ms-transform: translateY(0px);
                    transform: translateY(0px);
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label:hover ~ svg, .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] input:checked ~ svg {
                    opacity: 1;
                }

                    .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label:hover ~ svg polygon, .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] input:checked ~ svg polygon {
                        fill: #FF3333;
                    }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label:hover ~ strong, .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] input:checked ~ strong {
                    font-family: "Bold Cond", sans-serif;
                    text-transform: uppercase;
                    color: #FF3333;
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] svg {
                    position: absolute;
                    bottom: 100px;
                    left: 50%;
                    z-index: 5;
                    -ms-transform: translate(-50%, 50%);
                    transform: translate(-50%, 50%);
                    opacity: 1;
                    z-index: 1;
                }

                    .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] svg polygon {
                        fill: #006699;
                    }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label[for="small"] svg {
                    width: 31.93548387%;
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label[for="medium"] svg {
                    width: 45.80645161%;
                }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper div[data-size="quantity"] label[for="large"] svg {
                    width: 66.12903226%;
                }

            .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper .head5 {
                font-size: 22px;
                line-height: 25px;
                font-family: "Light Cond", sans-serif;
                text-align: center;
                color: #ffffff;
                padding: 30px 0 30px;
            }

            .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper button {
                font-family: "Bold Cond", sans-serif;
                font-size: 24px;
                line-height: 14px;
                color: #ffffff;
                background: #FF3333;
                border: 1px solid #FF3333;
                border-radius: 10px;
                padding: 15px;
                text-transform: none;
                margin: 20px;
                -ms-flex-item-align: left;
                -ms-grid-row-align: left;
                align-self: left;
            }

                .instaquote-container .carousel-box form [data-step="5"] .step-5-wrapper button:focus {
                    outline: none;
                }
