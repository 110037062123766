.social-wrapper .referral-phone {
    font-family: "Lexend-Bold", Arial, Helvetica, sans-serif;
    font-size: 29px;
}

    .social-wrapper .referral-phone span {
        font-size: 21px;
        display: block;
    }

.social-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    margin-top: 15px;
}

    .social-nav li {
        float: left;
        line-height: 1.42857143;
    }

    .social-nav a.social-link {
        position: relative;
        display: inline-block;
        height: 30px;
        width: 30px;
        background-color: #88d0e5;
        border-radius: 3em;
        text-align: center;
        margin: 4px 10px 4px 0;
        text-decoration: none !important;
        transition: background-color 1s;
    }

        .social-nav a.social-link span {
            position: absolute;
            font-size: 16px;
            color: #fff;
            top: 0;
            font-family: FontAwesome;
            transition: .5s;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        .social-nav a.social-link:hover span {
            color: #fff;
            text-decoration: none !important;
        }

    .social-nav a.facebook {
        background-color: #3b5998;
    }

    .social-nav .facebook span:after {
        content: "\f09a";
    }

    .social-nav a.twitter {
        background-color: #000;
    }

    .social-nav .twitter span:after {
        content: "\1d54f";
    }

    .social-nav a.linkedin {
        background-color: #007bb5;
    }

    .social-nav .linkedin span:after {
        content: "\f0e1";
    }

    .social-nav a.youtube {
        background-color: #bb0000;
    }

    .social-nav .youtube span:after {
        content: "\f167";
    }
