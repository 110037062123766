.container-fluid { margin-right: auto; margin-left: auto; padding-left: 15px; padding-right: 15px; max-width: 1220px; }
.row { margin-left: -15px; margin-right: -15px; }
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 { position: relative; min-height: 1px; padding-left: 15px; padding-right: 15px; }
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 { float: left; }
.col-xs-12 { box-sizing: border-box; width: 100%; }
.col-xs-11 { box-sizing: border-box; width: 91.66666667%; }
.col-xs-10 { box-sizing: border-box; width: 83.33333333%; }
.col-xs-9 { box-sizing: border-box; width: 75%; }
.col-xs-8 { box-sizing: border-box; width: 66.66666667%; }
.col-xs-7 { box-sizing: border-box; width: 58.33333333%; }
.col-xs-6 { box-sizing: border-box; width: 50%; }
.col-xs-5 { box-sizing: border-box; width: 41.66666667%; }
.col-xs-4 { box-sizing: border-box; width: 33.33333333%; }
.col-xs-3 { box-sizing: border-box; width: 25%; }
.col-xs-2 { box-sizing: border-box; width: 16.66666667%; }
.col-xs-1 { box-sizing: border-box; width: 8.33333333%; }
.col-xs-pull-12 { right: 100%; }
.col-xs-pull-11 { right: 91.66666667%; }
.col-xs-pull-10 { right: 83.33333333%; }
.col-xs-pull-9 { right: 75%; }
.col-xs-pull-8 { right: 66.66666667%; }
.col-xs-pull-7 { right: 58.33333333%; }
.col-xs-pull-6 { right: 50%; }
.col-xs-pull-5 { right: 41.66666667%; }
.col-xs-pull-4 { right: 33.33333333%; }
.col-xs-pull-3 { right: 25%; }
.col-xs-pull-2 { right: 16.66666667%; }
.col-xs-pull-1 { right: 8.33333333%; }
.col-xs-pull-0 { right: auto; }
.col-xs-push-12 { left: 100%; }
.col-xs-push-11 { left: 91.66666667%; }
.col-xs-push-10 { left: 83.33333333%; }
.col-xs-push-9 { left: 75%; }
.col-xs-push-8 { left: 66.66666667%; }
.col-xs-push-7 { left: 58.33333333%; }
.col-xs-push-6 { left: 50%; }
.col-xs-push-5 { left: 41.66666667%; }
.col-xs-push-4 { left: 33.33333333%; }
.col-xs-push-3 { left: 25%; }
.col-xs-push-2 { left: 16.66666667%; }
.col-xs-push-1 { left: 8.33333333%; }
.col-xs-push-0 { left: auto; }
.col-xs-offset-12 { margin-left: 100%; }
.col-xs-offset-11 { margin-left: 91.66666667%; }
.col-xs-offset-10 { margin-left: 83.33333333%; }
.col-xs-offset-9 { margin-left: 75%; }
.col-xs-offset-8 { margin-left: 66.66666667%; }
.col-xs-offset-7 { margin-left: 58.33333333%; }
.col-xs-offset-6 { margin-left: 50%; }
.col-xs-offset-5 { margin-left: 41.66666667%; }
.col-xs-offset-4 { margin-left: 33.33333333%; }
.col-xs-offset-3 { margin-left: 25%; }
.col-xs-offset-2 { margin-left: 16.66666667%; }
.col-xs-offset-1 { margin-left: 8.33333333%; }
.col-xs-offset-0 { margin-left: 0; }

@media (min-width:699px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 { float: left; }
    .col-sm-12 { box-sizing: border-box; width: 100%; }
    .col-sm-11 { box-sizing: border-box; width: 91.66666667%; }
    .col-sm-10 { box-sizing: border-box; width: 83.33333333%; }
    .col-sm-9 { box-sizing: border-box; width: 75%; }
    .col-sm-8 { box-sizing: border-box; width: 66.66666667%; }
    .col-sm-7 { box-sizing: border-box; width: 58.33333333%; }
    .col-sm-6 { box-sizing: border-box; width: 50%; }
    .col-sm-5 { box-sizing: border-box; width: 41.66666667%; }
    .col-sm-4 { box-sizing: border-box; width: 33.33333333%; }
    .col-sm-3 { box-sizing: border-box; width: 25%; }
    .col-sm-2 { box-sizing: border-box; width: 16.66666667%; }
    .col-sm-1 { box-sizing: border-box; width: 8.33333333%; }
    .col-sm-pull-12 { right: 100%; }
    .col-sm-pull-11 { right: 91.66666667%; }
    .col-sm-pull-10 { right: 83.33333333%; }
    .col-sm-pull-9 { right: 75%; }
    .col-sm-pull-8 { right: 66.66666667%; }
    .col-sm-pull-7 { right: 58.33333333%; }
    .col-sm-pull-6 { right: 50%; }
    .col-sm-pull-5 { right: 41.66666667%; }
    .col-sm-pull-4 { right: 33.33333333%; }
    .col-sm-pull-3 { right: 25%; }
    .col-sm-pull-2 { right: 16.66666667%; }
    .col-sm-pull-1 { right: 8.33333333%; }
    .col-sm-pull-0 { right: auto; }
    .col-sm-push-12 { left: 100%; }
    .col-sm-push-11 { left: 91.66666667%; }
    .col-sm-push-10 { left: 83.33333333%; }
    .col-sm-push-9 { left: 75%; }
    .col-sm-push-8 { left: 66.66666667%; }
    .col-sm-push-7 { left: 58.33333333%; }
    .col-sm-push-6 { left: 50%; }
    .col-sm-push-5 { left: 41.66666667%; }
    .col-sm-push-4 { left: 33.33333333%; }
    .col-sm-push-3 { left: 25%; }
    .col-sm-push-2 { left: 16.66666667%; }
    .col-sm-push-1 { left: 8.33333333%; }
    .col-sm-push-0 { left: auto; }
    .col-sm-offset-12 { margin-left: 100%; }
    .col-sm-offset-11 { margin-left: 91.66666667%; }
    .col-sm-offset-10 { margin-left: 83.33333333%; }
    .col-sm-offset-9 { margin-left: 75%; }
    .col-sm-offset-8 { margin-left: 66.66666667%; }
    .col-sm-offset-7 { margin-left: 58.33333333%; }
    .col-sm-offset-6 { margin-left: 50%; }
    .col-sm-offset-5 { margin-left: 41.66666667%; }
    .col-sm-offset-4 { margin-left: 33.33333333%; }
    .col-sm-offset-3 { margin-left: 25%; }
    .col-sm-offset-2 { margin-left: 16.66666667%; }
    .col-sm-offset-1 { margin-left: 8.33333333%; }
    .col-sm-offset-0 { margin-left: 0; }
}

@media (min-width:992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 { float: left; }
    .col-md-12 { box-sizing: border-box; width: 100%; }
    .col-md-11 { box-sizing: border-box; width: 91.66666667%; }
    .col-md-10 { box-sizing: border-box; width: 83.33333333%; }
    .col-md-9 { box-sizing: border-box; width: 75%; }
    .col-md-8 { box-sizing: border-box; width: 66.66666667%; }
    .col-md-7 { box-sizing: border-box; width: 58.33333333%; }
    .col-md-6 { box-sizing: border-box; width: 50%; }
    .col-md-5 { box-sizing: border-box; width: 41.66666667%; }
    .col-md-4 { box-sizing: border-box; width: 33.33333333%; }
    .col-md-3 { box-sizing: border-box; width: 25%; }
    .col-md-2 { box-sizing: border-box; width: 16.66666667%; }
    .col-md-1 { box-sizing: border-box; width: 8.33333333%; }
    .col-md-pull-12 { right: 100%; }
    .col-md-pull-11 { right: 91.66666667%; }
    .col-md-pull-10 { right: 83.33333333%; }
    .col-md-pull-9 { right: 75%; }
    .col-md-pull-8 { right: 66.66666667%; }
    .col-md-pull-7 { right: 58.33333333%; }
    .col-md-pull-6 { right: 50%; }
    .col-md-pull-5 { right: 41.66666667%; }
    .col-md-pull-4 { right: 33.33333333%; }
    .col-md-pull-3 { right: 25%; }
    .col-md-pull-2 { right: 16.66666667%; }
    .col-md-pull-1 { right: 8.33333333%; }
    .col-md-pull-0 { right: auto; }
    .col-md-push-12 { left: 100%; }
    .col-md-push-11 { left: 91.66666667%; }
    .col-md-push-10 { left: 83.33333333%; }
    .col-md-push-9 { left: 75%; }
    .col-md-push-8 { left: 66.66666667%; }
    .col-md-push-7 { left: 58.33333333%; }
    .col-md-push-6 { left: 50%; }
    .col-md-push-5 { left: 41.66666667%; }
    .col-md-push-4 { left: 33.33333333%; }
    .col-md-push-3 { left: 25%; }
    .col-md-push-2 { left: 16.66666667%; }
    .col-md-push-1 { left: 8.33333333%; }
    .col-md-push-0 { left: auto; }
    .col-md-offset-12 { margin-left: 100%; }
    .col-md-offset-11 { margin-left: 91.66666667%; }
    .col-md-offset-10 { margin-left: 83.33333333%; }
    .col-md-offset-9 { margin-left: 75%; }
    .col-md-offset-8 { margin-left: 66.66666667%; }
    .col-md-offset-7 { margin-left: 58.33333333%; }
    .col-md-offset-6 { margin-left: 50%; }
    .col-md-offset-5 { margin-left: 41.66666667%; }
    .col-md-offset-4 { margin-left: 33.33333333%; }
    .col-md-offset-3 { margin-left: 25%; }
    .col-md-offset-2 { margin-left: 16.66666667%; }
    .col-md-offset-1 { margin-left: 8.33333333%; }
    .col-md-offset-0 { margin-left: 0; }
}

@media (min-width:1280px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 { float: left; }
    .col-lg-12 { box-sizing: border-box; width: 100%; }
    .col-lg-11 { box-sizing: border-box; width: 91.66666667%; }
    .col-lg-10 { box-sizing: border-box; width: 83.33333333%; }
    .col-lg-9 { box-sizing: border-box; width: 75%; }
    .col-lg-8 { box-sizing: border-box; width: 66.66666667%; }
    .col-lg-7 { box-sizing: border-box; width: 58.33333333%; }
    .col-lg-6 { box-sizing: border-box; width: 50%; }
    .col-lg-5 { box-sizing: border-box; width: 41.66666667%; }
    .col-lg-4 { box-sizing: border-box; width: 33.33333333%; }
    .col-lg-3 { box-sizing: border-box; width: 25%; }
    .col-lg-2 { box-sizing: border-box; width: 16.66666667%; }
    .col-lg-1 { box-sizing: border-box; width: 8.33333333%; }
    .col-lg-pull-12 { right: 100%; }
    .col-lg-pull-11 { right: 91.66666667%; }
    .col-lg-pull-10 { right: 83.33333333%; }
    .col-lg-pull-9 { right: 75%; }
    .col-lg-pull-8 { right: 66.66666667%; }
    .col-lg-pull-7 { right: 58.33333333%; }
    .col-lg-pull-6 { right: 50%; }
    .col-lg-pull-5 { right: 41.66666667%; }
    .col-lg-pull-4 { right: 33.33333333%; }
    .col-lg-pull-3 { right: 25%; }
    .col-lg-pull-2 { right: 16.66666667%; }
    .col-lg-pull-1 { right: 8.33333333%; }
    .col-lg-pull-0 { right: auto; }
    .col-lg-push-12 { left: 100%; }
    .col-lg-push-11 { left: 91.66666667%; }
    .col-lg-push-10 { left: 83.33333333%; }
    .col-lg-push-9 { left: 75%; }
    .col-lg-push-8 { left: 66.66666667%; }
    .col-lg-push-7 { left: 58.33333333%; }
    .col-lg-push-6 { left: 50%; }
    .col-lg-push-5 { left: 41.66666667%; }
    .col-lg-push-4 { left: 33.33333333%; }
    .col-lg-push-3 { left: 25%; }
    .col-lg-push-2 { left: 16.66666667%; }
    .col-lg-push-1 { left: 8.33333333%; }
    .col-lg-push-0 { left: auto; }
    .col-lg-offset-12 { margin-left: 100%; }
    .col-lg-offset-11 { margin-left: 91.66666667%; }
    .col-lg-offset-10 { margin-left: 83.33333333%; }
    .col-lg-offset-9 { margin-left: 75%; }
    .col-lg-offset-8 { margin-left: 66.66666667%; }
    .col-lg-offset-7 { margin-left: 58.33333333%; }
    .col-lg-offset-6 { margin-left: 50%; }
    .col-lg-offset-5 { margin-left: 41.66666667%; }
    .col-lg-offset-4 { margin-left: 33.33333333%; }
    .col-lg-offset-3 { margin-left: 25%; }
    .col-lg-offset-2 { margin-left: 16.66666667%; }
    .col-lg-offset-1 { margin-left: 8.33333333%; }
    .col-lg-offset-0 { margin-left: 0; }
}

.embed-responsive { position: relative; display: block; height: 0; padding: 0; overflow: hidden; }
    .embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; left: 0; bottom: 0; height: 100%; width: 100%; border: 0; }
.embed-responsive-16by9 { padding-bottom: 56.25%; }
.embed-responsive-4by3 { padding-bottom: 75%; }
.well { min-height: 20px; padding: 19px; margin-bottom: 20px; background-color: #f5f5f5; border: 1px solid #e3e3e3; border-radius: 4px; -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05); box-shadow: inset 0 1px 1px rgba(0,0,0,0.05); }
    .well blockquote { border-color: #ddd; border-color: rgba(0,0,0,0.15); }
.well-lg { padding: 24px; border-radius: 6px; }
.well-sm { padding: 9px; border-radius: 3px; }
.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after { content: " "; display: table; }
.clearfix:after, .container:after, .container-fluid:after, .row:after { clear: both; }
.center-block { display: block; margin-left: auto; margin-right: auto; }
.pull-right { float: right !important; }
.pull-left { float: left !important; }
.hide { display: none !important; }
.show { display: block !important; }
.invisible { visibility: hidden; }
.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }
.hidden { display: none !important; }
.affix { position: fixed; }
@-ms-viewport { width: device-width; }
.visible-xs, .visible-sm, .visible-md, .visible-lg { display: none !important; }
.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block { display: none !important; }

@media (max-width:698px) {
    .visible-xs { display: block !important; }
    table.visible-xs { display: table !important; }
    tr.visible-xs { display: table-row !important; }
    th.visible-xs, td.visible-xs { display: table-cell !important; }
}

@media (max-width:698px) {
    .visible-xs-block { display: block !important; }
}

@media (max-width:698px) {
    .visible-xs-inline { display: inline !important; }
}

@media (max-width:698px) {
    .visible-xs-inline-block { display: inline-block !important; }
}

@media (min-width:699px) and (max-width:991px) {
    .visible-sm { display: block !important; }
    table.visible-sm { display: table !important; }
    tr.visible-sm { display: table-row !important; }
    th.visible-sm, td.visible-sm { display: table-cell !important; }
}

@media (min-width:699px) and (max-width:991px) {
    .visible-sm-block { display: block !important; }
}

@media (min-width:699px) and (max-width:991px) {
    .visible-sm-inline { display: inline !important; }
}

@media (min-width:699px) and (max-width:991px) {
    .visible-sm-inline-block { display: inline-block !important; }
}

@media (min-width:992px) and (max-width:1279px) {
    .visible-md { display: block !important; }
    table.visible-md { display: table !important; }
    tr.visible-md { display: table-row !important; }
    th.visible-md, td.visible-md { display: table-cell !important; }
}

@media (min-width:992px) and (max-width:1279px) {
    .visible-md-block { display: block !important; }
}

@media (min-width:992px) and (max-width:1279px) {
    .visible-md-inline { display: inline !important; }
}

@media (min-width:992px) and (max-width:1279px) {
    .visible-md-inline-block { display: inline-block !important; }
}

@media (min-width:1280px) {
    .visible-lg { display: block !important; }
    table.visible-lg { display: table !important; }
    tr.visible-lg { display: table-row !important; }
    th.visible-lg, td.visible-lg { display: table-cell !important; }
}

@media (min-width:1280px) {
    .visible-lg-block { display: block !important; }
}

@media (min-width:1280px) {
    .visible-lg-inline { display: inline !important; }
}

@media (min-width:1280px) {
    .visible-lg-inline-block { display: inline-block !important; }
}

@media (max-width:698px) {
    .hidden-xs { display: none !important; }
}

@media (min-width:699px) and (max-width:991px) {
    .hidden-sm { display: none !important; }
}

@media (min-width:992px) and (max-width:1279px) {
    .hidden-md { display: none !important; }
}

@media (min-width:1280px) {
    .hidden-lg { display: none !important; }
}

.visible-print { display: none !important; }

@media print {
    .visible-print { display: block !important; }
    table.visible-print { display: table !important; }
    tr.visible-print { display: table-row !important; }
    th.visible-print, td.visible-print { display: table-cell !important; }
}

.visible-print-block { display: none !important; }

@media print {
    .visible-print-block { display: block !important; }
}

.visible-print-inline { display: none !important; }

@media print {
    .visible-print-inline { display: inline !important; }
}

.visible-print-inline-block { display: none !important; }

@media print {
    .visible-print-inline-block { display: inline-block !important; }
}

@media print {
    .hidden-print { display: none !important; }
}
